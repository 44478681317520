import "./LineChart.css";
import moment from "moment";

import {
  ResponsiveContainer,
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Line,
  ReferenceArea,
  Tooltip,
} from "recharts";
import {
  ChartObject,
  DataObject,
} from "../../../../../api/requests/timeSeries";
import React, { useCallback } from "react";
import { Empty, Checkbox, Button } from "antd";
import { Aggregation, SET_ZOOM } from "../../../../../actions/lineChart";
import { lineColors } from "../../../costants";
import { ZoomUpdates, Zoom } from "../../../../types/timeseries/types";
import { ZoomOutOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

type LineChartProps = {
  SetZoom: (type: SET_ZOOM, ref: ZoomUpdates, key: string) => void;
  data_chart: ChartObject;
  aggregations_view: string[];
  zoom: Zoom;
  SetAggregationView: (aggregations: string[], key: string) => void;
  width: number;
  variableName: string;
};

const CustomChart: React.FC<LineChartProps> = ({
  data_chart,
  aggregations_view,
  zoom,
  SetAggregationView,
  SetZoom,
  width,
  variableName,
}) => {
  const { t } = useTranslation();

  const dateFormatter = (date: string) => {
    return moment(date).format("DD/MM/YY HH:mm");
  };

  const mergeDataArrays = (data_chart: ChartObject) => {
    let res: DataObject[] = [];
    for (const [, value] of Object.entries(data_chart)) {
      res = [...res, ...(value || [])];
    }
    return res;
  };

  const chartLines = useCallback(
    (data_chart: ChartObject) => {
      let res: any[] = [];
      const finalData = zoom.isZoomed ? zoom.data : data_chart;
      for (const [key, value] of Object.entries(finalData)) {
        if (value && value?.length > 0 && aggregations_view.includes(key)) {
          res = [
            <Line
              isAnimationActive={false}
              type="natural"
              dataKey="value"
              data={value}
              name={key}
              key={key}
              stroke={(lineColors as any)[key as Aggregation]}
              yAxisId={"1"}
            />,
            ...res,
          ];
        }
      }
      return res;
    },
    [aggregations_view, zoom]
  );

  function onChange(checkedValues: any) {
    SetAggregationView(checkedValues, variableName);
  }

  const getAxisYDomain = (
    data_chart: ChartObject,
    from: string,
    to: string,
    ref: string,
    offset: number
  ) => {
    const refData: DataObject[] = mergeDataArrays(data_chart).filter(
      (data) =>
        new Date(data.ts) >= new Date(from) && new Date(data.ts) <= new Date(to)
    );
    let filteredData: ChartObject = {};
    for (const [key, value] of Object.entries(data_chart)) {
      filteredData[key as Aggregation] =
        value?.filter(
          (data) =>
            new Date(data.ts) >= new Date(from) &&
            new Date(data.ts) <= new Date(to)
        ) || [];
    }
    if (refData.length > 0) {
      const bottom = Math.min(
        ...refData.reduce((a, o) => (a.push(o.value), a), [] as number[])
      );
      const top = Math.max(
        ...refData.reduce((a, o) => (a.push(o.value), a), [] as number[])
      );
      SetZoom(
        "SET_ZOOM",
        {
          data: filteredData,
        },
        variableName
      );
      return [(bottom | 0) - offset, (top | 0) + offset];
    }
    return [0, 100];
  };

  const do_zoom = (d_chart: ChartObject) => {
    if (zoom.refAreaLeft === zoom.refAreaRight || zoom.refAreaRight === "") {
      SetZoom("SET_ZOOM", { refAreaLeft: "", refAreaRight: "" }, variableName);
      return;
    }

    // xAxis domain
    if (zoom.refAreaLeft > zoom.refAreaRight)
      [zoom.refAreaLeft, zoom.refAreaRight] = [
        zoom.refAreaRight,
        zoom.refAreaLeft,
      ];

    // yAxis domain
    const [bottom, top] = getAxisYDomain(
      d_chart,
      zoom.refAreaLeft,
      zoom.refAreaRight,
      "value",
      0
    );

    SetZoom(
      "SET_ZOOM",
      {
        refAreaLeft: "",
        refAreaRight: "",
        left: zoom.refAreaLeft,
        right: zoom.refAreaRight,
        bottom: bottom.toString(),
        top: top.toString(),
        isZoomed: true,
      },
      variableName
    );
  };

  const zoomOut = () => {
    SetZoom(
      "SET_ZOOM",
      {
        data: {},
        left: "",
        right: "",
        refAreaLeft: "",
        refAreaRight: "",
        top: "dataMax+1",
        bottom: "dataMin-1",
        animation: true,
        isZoomed: false,
      },
      variableName
    );
  };

  const checkCheckBox = (aggregations_view: string[]) => {
    if (variableName === "summary") {
      return [
        { label: "s2", value: "s2" },
        { label: "s3", value: "s3" },
        { label: "s4", value: "s4" },
        { label: "s5", value: "s5" },
        { label: "s6", value: "s6" },
        { label: "s7", value: "s7" },
        { label: "s8", value: "s8" },
      ];
    } else {
      return [
        { label: "avg", value: "avg" },
        { label: "max", value: "max" },
        { label: "min", value: "min" },
      ];
    }
  };

  return (
    <>
      {mergeDataArrays(data_chart).length > 0 ? (
        <>
          <div className={width <= 575 ? "chartStyleResponsive" : "chartStyle"}>
            <Checkbox.Group
              className={
                width <= 575 ? "checkboxStyleResponsive" : "checkboxStyle"
              }
              options={checkCheckBox(aggregations_view)}
              defaultValue={aggregations_view}
              onChange={onChange}
            />
            <ResponsiveContainer width="100%" maxHeight={280} height={280}>
              <LineChart
                onMouseDown={(e: any) =>
                  e !== null
                    ? SetZoom(
                        "SET_ZOOM",
                        { refAreaLeft: e.activeLabel?.toString() ?? "" },
                        variableName
                      )
                    : null
                }
                onMouseMove={(e: any) =>
                  e !== null
                    ? zoom.refAreaLeft !== "" &&
                      SetZoom(
                        "SET_ZOOM",
                        { refAreaRight: e.activeLabel?.toString() ?? "" },
                        variableName
                      )
                    : null
                }
                onMouseUp={() => do_zoom(data_chart)}
              >
                <CartesianGrid />
                {aggregations_view.length > 0 ? (
                  <XAxis
                    dataKey={"ts"}
                    name={`${t("data")}`}
                    tickFormatter={dateFormatter}
                    allowDuplicatedCategory={false}
                  />
                ) : null}
                <YAxis
                  type="number"
                  domain={[zoom.bottom, zoom.top]}
                  dataKey={"value"}
                  name={`${t("value")}`}
                  yAxisId={"1"}
                />
                <Tooltip
                  wrapperStyle={{ zIndex: 101 }}
                  labelFormatter={(value: string) => {
                    let result: string[] = [];
                    data_chart?.min?.map((el) => {
                      if (String(el.ts) === String(value)) {
                        result.push("min: " + el.value);
                      }
                    });
                    data_chart?.max?.map((el) => {
                      if (String(el.ts) === String(value)) {
                        result.push("max: " + el.value);
                      }
                    });
                    data_chart?.s2?.map((el) => {
                      if (String(el.ts) === String(value)) {
                        result.push("s2: " + el.value);
                      }
                    });
                    data_chart?.s3?.map((el) => {
                      if (String(el.ts) === String(value)) {
                        result.push("s3: " + el.value);
                      }
                    });
                    data_chart?.s4?.map((el) => {
                      if (String(el.ts) === String(value)) {
                        result.push("s4: " + el.value);
                      }
                    });
                    data_chart?.s5?.map((el) => {
                      if (String(el.ts) === String(value)) {
                        result.push("s5: " + el.value);
                      }
                    });
                    data_chart?.s6?.map((el) => {
                      if (String(el.ts) === String(value)) {
                        result.push("s6: " + el.value);
                      }
                    });
                    data_chart?.s7?.map((el) => {
                      if (String(el.ts) === String(value)) {
                        result.push("s7: " + el.value);
                      }
                    });
                    return (
                      <div>
                        {result.map((r) => (
                          <div>{r}</div>
                        ))}
                      </div>
                    );
                  }}
                />

                {chartLines(data_chart)}
                <Legend className="legend" />
                {zoom.refAreaLeft && zoom.refAreaRight ? (
                  <ReferenceArea
                    yAxisId="1"
                    x1={zoom.refAreaLeft}
                    x2={zoom.refAreaRight}
                    strokeOpacity={0.3}
                  />
                ) : null}
              </LineChart>
            </ResponsiveContainer>
          </div>
          {width > 575 ? (
            <div className="options">
              <Button
                size="large"
                type="primary"
                shape="circle"
                icon={<ZoomOutOutlined />}
                disabled={!zoom.isZoomed}
                onClick={() => zoomOut()}
              />
            </div>
          ) : null}
        </>
      ) : (
        <Empty />
      )}
    </>
  );
};

export default CustomChart;
