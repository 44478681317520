import React from "react";
import ReactDOM from "react-dom";
import "./App.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { applyMiddleware, createStore } from "redux";
import { Provider } from "react-redux";
import rootReducer from "./reducers/index";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";
import { CssBaseline } from "@material-ui/core";
import CustomThemeProvider from "./components/CustomThemeProvider";
import "./i18n";

const middlewares = [thunkMiddleware];
const middlewareEnhancer = applyMiddleware(...middlewares);

const enhancers = [middlewareEnhancer];
const composedEnhancers = composeWithDevTools(...enhancers);

const configureStore = () => {
  return createStore(rootReducer, composedEnhancers);
};
const store = configureStore();

ReactDOM.render(
  <CustomThemeProvider>
    <CssBaseline />
    <Provider store={store}>
      <App />
    </Provider>
  </CustomThemeProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
