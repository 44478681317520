import "./resetPassword.css";
import "antd/dist/antd.css";
import { Result } from "antd";
import React, { useEffect, useState } from "react";
import logo from "../../../logo/Logo_LETOMEC_Orizzontale_1.png";
import { useTranslation } from "react-i18next";

const ResultResetPassword: React.FC = () => {
  const { t } = useTranslation();

  document.body.style.backgroundColor = "#011529";
  const [width, setWidth] = useState<number>(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <div className={width <= 768 ? "recover-box-responsive" : "recover-box"}>
      <div className="image-container">
        <img alt="image" src={logo} className="image" />
      </div>
      <Result
        status="success"
        title={t("emailSentSuccess")}
        subTitle={t("checkEmail")}
      />
    </div>
  );
};

export default ResultResetPassword;
