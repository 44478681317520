import { LockOutlined } from "@ant-design/icons";
import { InputType } from "../../types/form/types";
import { confirmPasswordRules, passwordRules } from "../../types/form/rules";

export const inputs: (t: any) => InputType[] = (t: any) => [
  {
    key: "password",
    name: "password",
    label: t("password"),
    type: "password",
    icon: <LockOutlined className="site-form-item-icon" />,
    rules: passwordRules(t),
  },
  {
    key: "confirm_password",
    name: "confirm_password",
    label: t("confirmPassword"),
    type: "password",
    icon: <LockOutlined className="site-form-item-icon" />,
    rules: confirmPasswordRules(t),
  },
];
