import ButtonItem from "../../ButtonItem";
import ButtonConfItem from "../../ButtonConfItem";
import { Col, Form, message, Row } from "antd";
import {
  GetUser,
  UpdateUser as ApiUpdateUser,
} from "../../../api/services/userService";
import React, { useEffect, useState } from "react";
import "../../navbar/Navbar.css";
import TitleWithBack from "../../../containers/TitleWithBack";
import "../../../App.css";
import { User } from "../../../api/requests/userService";
import { userFields } from "./inputs";
import "./UpdateUser.css";
import { useHistory } from "react-router-dom";
import FormInput from "../../input/FormInput";
import { countries } from "../../countries";
import FormSelect from "../../select/FormSelect";
import { useTranslation } from "react-i18next";

type UpdateUserProps = {
  user_id: string;
};

const UpdateUser: React.FC<UpdateUserProps> = ({ user_id }) => {
  const { t } = useTranslation();

  const [width, setWidth] = useState<number>(window.innerWidth);
  const [height, setHeight] = useState<number>(window.innerHeight);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  useEffect(() => {
    GetUser({ user_id: user_id }).then((res) => {
      if (res && res.user) {
        setSelectedUser(res.user);
      }
    });
  }, []);

  const formItemLayout = {
    labelCol: {
      sm: {
        span: 8,
      },
    },
    wrapperCol: {
      sm: {
        span: 20,
      },
    },
  };

  const history = useHistory();
  const [form] = Form.useForm();
  const handleSubmit = () => {
    if (selectedUser) {
      form.validateFields().then((values) => {
        ApiUpdateUser({
          user_id: selectedUser?.uid || "",
          name: values.name,
          email: values.email,
          user_info: {
            phone: values.phone,
            address: values.address,
            city: values.city,
            province: values.province,
            country: values.country,
            zip_code: values.zip_code,
          },
        }).then((res) => {
          if (res && res.result) {
            history.goBack();
            message.success(t("userUpdateSuccessfully"), 4);
          } else {
            message.error(res?.err?.message || t("userUpdateError"), 4);
          }
        });
      });
    }
  };

  if (!selectedUser) {
    return null;
  }

  return (
    <>
      <TitleWithBack title={t("updateUser")} key="update_user_title" />
      <div
        className="my-updateuser-container my-updateuser-container-responsive"
        key="update_user_div"
      >
        <Form
          {...formItemLayout}
          layout="vertical"
          key={1}
          form={form}
          name="user_panel"
        >
          <Row gutter={24} key="companyInfo">
            {userFields(t).map((el, index) => {
              return (
                <Col span={width <= 575 || height <= 815 ? 12 : 8} key={index}>
                  {el.type !== "select" ? (
                    <FormInput
                      key={el.key + "forminput"}
                      keyValue={el.key}
                      name={el.name}
                      placeholder={el.label}
                      type={el.type === undefined ? "text" : el.type}
                      value={
                        (selectedUser as any)[el.key] ||
                        (selectedUser as any)["user_info"][el.key]
                      }
                    />
                  ) : (
                    <FormSelect
                      key={el.key + "forminput"}
                      placeholder={el.label}
                      keyValue={el.key}
                      name={el.name}
                      options={countries}
                      value={
                        (selectedUser as any)[el.key] ||
                        (selectedUser as any)["user_info"][el.key]
                      }
                    />
                  )}
                </Col>
              );
            })}
          </Row>
          <div className="btn-container" key="btn_div">
            <ButtonConfItem
              buttonLabel={t("reset")}
              buttonOnConfirm={() => form.resetFields()}
              buttonOnCancel={() => {}}
              questionLabel={`${t("areYouSure")}`}
            />
            <ButtonItem
              buttonType="primary"
              label={t("ok")}
              buttonOnClick={handleSubmit}
            />
          </div>
        </Form>
      </div>
    </>
  );
};

export default UpdateUser;
