import "./charts/lineChart/LineChart.css";
import "../../../App.css";
import React, { useEffect, useState } from "react";
import { Col, DatePicker, Form, Row } from "antd";
import ButtonItem from "../../ButtonItem";
import ButtonConfItem from "../../ButtonConfItem";
import FormSelect from "../../select/FormSelect";
import {
  helios2GalvInput,
  helios2Input,
  helios3Input,
  helios4HotProbeInput,
} from "./inputs";
import {
  helios2GalvModel,
  helios2Model,
  helios3Model,
  helios4HotProbeModel,
} from "./costant";
import "../../input/FormInput.css";
import InstallationSummary from "./summaryCards/InstallationSummary";
import { Installation } from "../../../api/requests/installationsService";
import { useTranslation } from "react-i18next";

type FormComparatorProps = {
  form: any;
  handleSubmit: any;
  model: string;
  installation: Installation;
};

const FormComparator: React.FC<FormComparatorProps> = ({
  form,
  handleSubmit,
  model,
  installation,
}) => {
  const { t } = useTranslation();

  const initialSample = form.getFieldValue("sampleType");
  let initialHidden = true;
  if (
    initialSample &&
    initialSample.length === 1 &&
    initialSample[0].toLowerCase() === "other"
  ) {
    initialHidden = false;
  }

  const [hidden, setHidden] = useState<boolean>(initialHidden);

  const [width, setWidth] = useState<number>(window.innerWidth);
  const [height, setHeight] = useState<number>(window.innerHeight);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  let selected_model: any[] = [];
  switch (model) {
    case helios2Model:
      selected_model = helios2Input(t);
      break;
    case helios2GalvModel:
      selected_model = helios2GalvInput(t);
      break;
    case helios3Model:
      selected_model = helios3Input(t);
      break;
    case helios4HotProbeModel:
      selected_model = helios4HotProbeInput(hidden, setHidden, t);
      break;
    default:
      selected_model = [];
      break;
  }

  return (
    <>
      <Row className="cards">
        <Col xs={24} xl={10} className="card-responsive">
          <InstallationSummary selected_installation={installation} />
        </Col>
      </Row>
      <Form layout="vertical" key={1} form={form} name="sessionComparator">
        <Row gutter={24} key="sessionComparator">
          {selected_model.map((el, index) => {
            if (el.hidden === true) {
              return null;
            }
            return el.type === "date" ? (
              <Col span={width <= 575 || height <= 815 ? 24 : 12} key={index}>
                <div className="form__group field" key={el.key + "div"}>
                  <label
                    key={el.key + "label"}
                    htmlFor="name"
                    className="form__label"
                  >
                    {el.label}
                  </label>
                  <Form.Item name={el.name} rules={el.rules}>
                    <DatePicker.RangePicker className="datepicker" />
                  </Form.Item>
                </div>
              </Col>
            ) : (
              <Col span={width <= 575 || height <= 815 ? 24 : 12} key={index}>
                <FormSelect
                  key={el.key + "forminput"}
                  placeholder={el.label}
                  keyValue={el.key}
                  name={el.name}
                  options={el?.options || []}
                  tags={el.type === "multiple"}
                  rules={el.rules}
                />
              </Col>
            );
          })}
        </Row>
      </Form>
      <div className="btn-container" key={"btn_div"}>
        <ButtonConfItem
          buttonLabel={t("reset")}
          buttonOnConfirm={() => {
            form.resetFields();
          }}
          buttonOnCancel={() => {}}
          questionLabel={`${t("areYouSure")}`}
        />
        <ButtonItem
          buttonType="primary"
          label={t("ok")}
          buttonOnClick={handleSubmit}
        />
      </div>
    </>
  );
};
export default FormComparator;
