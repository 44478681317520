import { connect } from 'react-redux'
import { Dispatch } from 'react'
import { Action } from '../actions'
import { Store } from '../reducers'
import Navbar from '../components/navbar/Navbar'

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        setNavbarIsCollapsed: (collapsed: boolean) => dispatch({type: "SET_NAVBAR_IS_COLLAPSED", collapsed: collapsed}),
        setRole: (role: string) => dispatch({type: "SET_ROLE", role: role}),
        setItem: (item: string) => dispatch({type: "SET_SELECTED_ITEM", item: item}),
        setPanelIsOpen: (o: boolean) => dispatch({type: "SET_PANEL_IS_OPEN", open: o})
    }
}

const mapStateToProps = (state: Store) => {
    return {
        navbar_collapsed: state.info.navbar_collapsed,
        panel_open: state.navbar.panel_open,
        selected: state.navbar.selected
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar)