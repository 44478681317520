import "../../App.css";
import React, { useEffect } from "react";
import Navbar from "../../containers/Navbar";
import HeaderItem from "../../containers/HeaderItem";
import { Route, Switch, useHistory } from "react-router-dom";
import {
  dataHistoryPanelUrl,
  installationListToDataHistoryUrl,
  installationListToLineChartUrl,
  installationListToReportsPageUrl,
  installationPageUrl,
  ReportsInstallationUrl,
  routes,
} from "./costants";
import Profile from "./profile/Profile";
import CreateCustomer from "../../containers/pages/createCustomer/CreateCustomer";
import InstallationPage from "../../containers/pages/installationPage/InstallationPage";
import CustomerListTable from "../../containers/pages/UserListTable";
import UpdateUserForm from "../../containers/pages/updateUser/UpdateUser";
import AddSubscription from "../../containers/pages/addSubscription/AddSubscription";
import UpdateSubscription from "../../containers/pages/updateSubscription/UpdateSubscription";
import AddInstallation from "../../containers/pages/addInstallation/AddInstallation";
import InstallationListPage from "../../containers/pages/installationsListPages/InstallationListPage";
import UpdateInstallation from "../../containers/pages/updateInstallation/UpdateInstallation";
import AlarmTable from "../../containers/pages/alarmsPage/AlarmTable";
import DataHistoryPanel from "../../containers/pages/dataExportDownload/DataHistoryPanel";
import DeviceConnectionConfiguration from "../../containers/pages/deviceConnectionConfiguration/DeviceConnectionConfiguration";
import InstallationListSubscriptionSummary from "../../containers/pages/installationsListPages/InstallationsListSubscriptionSummary";
import { installationsListColumns } from "./installationsList/inputs";
import SubscriptionListToUpdate from "../../containers/pages/SelectSubscription";
import SubscriptionTable from "../../containers/pages/subscriptionsListPage/SubscriptionTable";
import Success from "./addSubscription/Success";
import { GetUserProfile } from "../../api/services/userService";
import ReportsInstallation from "./reportsInstallation/ReportsInstallation";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { useStyles } from "../../constants/muiStyles";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

const InstallationsListToData = (props: any) => {
  const { t } = useTranslation();
  const { id } = props.match.params;

  return (
    <InstallationListSubscriptionSummary
      subscription_id={id}
      columns={installationsListColumns}
      redirectUrl={installationPageUrl}
    />
  );
};

const InstallationsListToHistory = (props: any) => {
  const { id } = props.match.params;
  return (
    <InstallationListSubscriptionSummary
      subscription_id={id}
      columns={installationsListColumns}
      redirectUrl={dataHistoryPanelUrl}
    />
  );
};

const InstallationListToReport = (props: any) => {
  const { id } = props.match.params;
  return (
    <InstallationListSubscriptionSummary
      subscription_id={id}
      columns={installationsListColumns}
      redirectUrl={ReportsInstallationUrl}
    />
  );
};

const InstallationPageView = (props: any) => {
  const { id } = props.match.params;
  return <InstallationPage installation_id={id} />;
};

const UpdateInstallationView = (props: any) => {
  const { id } = props.match.params;
  return <UpdateInstallation installationId={id} />;
};

const UpdateSubscriptionView = (props: any) => {
  const { id } = props.match.params;
  return <UpdateSubscription subscriptionId={id} />;
};

const SubListToUpdateView = () => {
  return <SubscriptionListToUpdate />;
};

const SubListToInstallationView = () => {
  return <SubscriptionTable link={installationListToLineChartUrl} />;
};

const SubListToDataHistory = () => {
  return <SubscriptionTable link={installationListToDataHistoryUrl} />;
};

const SubListToReports = () => {
  return <SubscriptionTable link={installationListToReportsPageUrl} />;
};

const DataHistoryView = (props: any) => {
  const { id } = props.match.params;
  return <DataHistoryPanel installation_id={id} />;
};

const UpdateUserView = (props: any) => {
  const { id } = props.match.params;
  return <UpdateUserForm user_id={id} />;
};

const ReportsInstallationView = (props: any) => {
  const { id } = props.match.params;
  return <ReportsInstallation installationId={id} />;
};

const DeviceConnectionConfigurationView = (props: any) => {
  const { id } = props.match.params;
  return <DeviceConnectionConfiguration installation_id={id} />;
};

const MainPage: React.FC = () => {
  document.body.style.backgroundColor = "white";
  const history = useHistory();

  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  useEffect(() => {
    GetUserProfile().then((res) => {
      if (!res || !res.user) {
        history.push("/login");
      }
    });
  }, []);

  return (
    <>
      <Navbar />
      <HeaderItem />
      <div
        className={
          isMobile
            ? clsx(classes.content)
            : clsx(classes.content, { [classes.contentShift]: true })
        }
      >
        <Switch>
          <Route exact path={routes.mainPage} component={Profile} />
          <Route exact path={routes.profile} component={Profile} />
          <Route exact path={routes.addCustomer} component={CreateCustomer} />
          <Route
            exact
            path={routes.installationPage}
            component={InstallationPageView}
          />
          <Route
            exact
            path={routes.customersList}
            component={CustomerListTable}
          />
          <Route exact path={routes.updateUser} component={UpdateUserView} />
          <Route
            exact
            path={routes.installationsListToLineChart}
            component={InstallationsListToData}
          />
          <Route
            exact
            path={routes.installationsListToDataHistory}
            component={InstallationsListToHistory}
          />
          <Route
            exact
            path={routes.addSubscription}
            component={AddSubscription}
          />
          <Route
            exact
            path={routes.subscriptionsListToUpdate}
            component={SubListToUpdateView}
          />
          <Route
            exact
            path={routes.updateSubscription}
            component={UpdateSubscriptionView}
          />
          <Route
            exact
            path={routes.subscriptionsListToInstallationPage}
            component={SubListToInstallationView}
          />
          <Route
            exact
            path={routes.subscriptionsListToDataHistory}
            component={SubListToDataHistory}
          />
          <Route
            exact
            path={routes.addInstallation}
            component={AddInstallation}
          />
          <Route
            exact
            path={routes.installationsListToUpdate}
            component={InstallationListPage}
          />
          <Route
            exact
            path={routes.updateInstallation}
            component={UpdateInstallationView}
          />
          <Route exact path={routes.alarms} component={AlarmTable} />
          <Route
            exact
            path={routes.dataHistoryPanel}
            component={DataHistoryView}
          />
          <Route
            exact
            path={routes.deviceConnectionConfiguration}
            component={DeviceConnectionConfigurationView}
          />
          <Route exact path={routes.success} component={Success} />
          <Route
            exact
            path={routes.subscriptionsListToReports}
            component={SubListToReports}
          />
          <Route
            exact
            path={routes.installationListToReports}
            component={InstallationListToReport}
          />
          <Route
            exact
            path={routes.reportsInstallation}
            component={ReportsInstallationView}
          />
        </Switch>
      </div>
    </>
  );
};

export default MainPage;
