import { message, Popconfirm, Switch } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { Subscription } from "../../../api/requests/subscription";
import {
  getAllSubscriptions,
  updateSubscriptionStatus,
} from "../../../api/services/subscriptionService";
import "../../../App.css";
import { updateSubscriptionUrl } from "../costants";
import CustomTitle from "../../CustomTitle";
import "./SelectSubscription.css";
import { useHistory } from "react-router-dom";
import { subscriptionsTableColumns } from "./inputs";
import TableWithoutGetDataFunction from "../../tableWithoutGetDataFunction";
import { useTranslation } from "react-i18next";

type SelectSubscriptionPanelProps = {
  role: string;
};

const SelectSubscription: React.FC<SelectSubscriptionPanelProps> = ({
  role,
}) => {
  const { t } = useTranslation();

  const history = useHistory();
  const linkFunction: (value: string) => void = (value: string) =>
    history.push(updateSubscriptionUrl(value));

  const [tableData, setTableData] = useState<Object[]>([]);
  const [totalDataLength, setTotalDataLength] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [currentSort, setCurrentSort] = useState<string>("expiry_date");
  const [currentSearch, setCurrentSearch] = useState<string>("");

  useEffect(() => {
    getAllSubscriptions("0", currentSort, currentSearch.toString()).then(
      (res) => {
        if (res && res.subscriptions) {
          setTableData(res.subscriptions);
          setTotalDataLength(res?.total_count ?? 0);
        }
      }
    );
  }, []);

  const getSubscriptions = useCallback(() => {
    getAllSubscriptions(
      currentPage.toString(),
      currentSort.toString(),
      currentSearch.toString()
    ).then((res) => {
      if (res && res.subscriptions) {
        setTableData(res.subscriptions);
      }
    });
  }, [currentPage, currentSort, currentSearch]);

  const changePage = (page: number) => {
    setCurrentPage(page);
    getAllSubscriptions(
      String(page),
      currentSort,
      currentSearch.toString()
    ).then((res) => {
      if (res && res.subscriptions) {
        setTableData(res.subscriptions);
      }
    });
  };

  const changeSort = (sort: string) => {
    setCurrentSort(sort);
    getAllSubscriptions(
      String(currentPage),
      sort,
      currentSearch.toString()
    ).then((res) => {
      if (res && res.subscriptions) {
        setTableData(res.subscriptions);
      }
    });
  };

  const changeSearch = (text: string) => {
    setCurrentSearch(text);
    getAllSubscriptions(
      String(currentPage),
      currentSearch.toString(),
      text
    ).then((res) => {
      if (res && res.subscriptions) {
        setTableData(res.subscriptions);
      }
    });
  };

  const updateState = (record: Subscription) => {
    return (
      <div className="button">
        <Popconfirm
          title={t("sureQuestion")}
          onConfirm={() => {
            updateSubscriptionStatus({
              subscription_id: record.id,
              status: record.status === "enabled" ? "disabled" : "enabled",
            }).then((res) => {
              if (res && res.result && !res.error) {
                message.success(res.result, 4);
                getSubscriptions();
              } else {
                message.error(res?.error || t("failedOperation"), 4);
              }
            });
          }}
          onCancel={() => {}}
          okText={t("yes")}
          cancelText={t("no")}
        >
          <Switch size="small" checked={record?.status === "enabled"} />
        </Popconfirm>
      </div>
    );
  };

  return (
    <>
      <CustomTitle title={t("updateSubscription")} />
      <TableWithoutGetDataFunction
        changeSort={changeSort}
        changePage={changePage}
        changeSearch={changeSearch}
        columns={subscriptionsTableColumns(linkFunction, role, updateState, t)}
        data={tableData}
        totalDataLength={totalDataLength}
      />
    </>
  );
};

export default SelectSubscription;
