import { installationFields } from "./inputs";
import ButtonItem from "../../ButtonItem";
import ButtonConfItem from "../../ButtonConfItem";
import { Form, message } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import TitleWithBack from "../../../containers/TitleWithBack";
import {
  getInstallation,
  UpdateInstallation as update,
} from "../../../api/services/installationsService";
import { Installation } from "../../../api/requests/installationsService";
import "./UpdateInstallation.css";
import { useHistory } from "react-router-dom";
import FormInput from "../../input/FormInput";
import { InstallationsServiceUrl } from "../../../api/constants";
import { useTranslation } from "react-i18next";

type UpdateInstallationProps = {
  installationId: string;
};

const UpdateInstallation: React.FC<UpdateInstallationProps> = ({
  installationId,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const donwloadPackage = useCallback(() => {
    // Create a link element
    const link = document.createElement("a");

    // Set link's href to point to the Blob URL
    link.href = `${InstallationsServiceUrl}/${installationId}/package`;
    link.download = "download.csv";

    // Append link to the body
    document.body.appendChild(link);

    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );
    // Remove link from body
    document.body.removeChild(link);
  }, []);

  const [loading, setLoading] = useState<boolean>(true);
  const [selectedInstallation, setSelectedInstallation] =
    useState<Installation | null>(null);
  useEffect(() => {
    getInstallation({ id: installationId }).then((res) => {
      if (res && res.installation) {
        setSelectedInstallation(res.installation);
      } else {
        message.error(res?.err?.message || t("failedOperation"), 4);
      }
      setLoading(false);
    });
  }, []);

  const [form] = Form.useForm();

  const submit = () => {
    form.validateFields().then((values) => {
      update({
        name: values.name,
        id: selectedInstallation?.id ?? "",
        description: values.description,
        model: selectedInstallation?.model ?? "",
      }).then((res) => {
        if (res !== undefined && !res.err) {
          message.success(t("updateInstallationSuccess"), 4);
          form.resetFields();
          history.goBack();
        } else {
          message.error(res?.err?.message ?? t("updateInstallationError"), 4);
        }
      });
    });
  };

  if (loading) {
    return null;
  }

  return (
    <>
      <TitleWithBack
        title={t("updateInstallation")}
        key="update_installation_title"
      />
      <div
        className="my-updateinstallation-container my-updateinstallation-container-responsive"
        key="update_installation_div"
      >
        <Form layout="vertical" key={1} form={form} name="user_panel">
          {installationFields(t).map((el) => (
            <FormInput
              key={el.key + "forminput"}
              value={(selectedInstallation as any)[el.key]}
              keyValue={el.key}
              name={el.name}
              placeholder={el.label}
              type={el.type === undefined ? "text" : el.type}
            />
          ))}
          <div className="btn-container" key="update_installation_btn">
            <ButtonItem
              buttonType="dashed"
              label={t("regenerateUploader")}
              buttonOnClick={donwloadPackage}
            />
            <ButtonConfItem
              buttonLabel={t("reset")}
              buttonOnConfirm={() => form.resetFields()}
              buttonOnCancel={() => {}}
              questionLabel={`${t("areYouSure")}`}
            />
            <ButtonItem
              buttonType="primary"
              label={t("ok")}
              buttonOnClick={submit}
            />
          </div>
        </Form>
      </div>
    </>
  );
};

export default UpdateInstallation;
