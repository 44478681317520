import "./RecoverPassword.css";
import "antd/dist/antd.css";
import ButtonItem from "../../ButtonItem";
import { inputs } from "./inputs";
import { Form, Input, message } from "antd";
import React, { useEffect, useState } from "react";
import queryString from "querystring";
import { RouteComponentProps } from "react-router";
import { recoverPassword } from "../../../api/services/loginService";
import { useHistory } from "react-router-dom";
import { recoverPageUrlResult } from "../costants";
import logo from "../../../logo/Logo_LETOMEC_Orizzontale_1.png";
import { useTranslation } from "react-i18next";

const RecoverPassword: React.FC<RouteComponentProps> = (props) => {
  const { t } = useTranslation();

  document.body.style.backgroundColor = "#011529";
  const [token, setToken] = useState<string | string[]>("");
  const [width, setWidth] = useState<number>(window.innerWidth);
  const history = useHistory();

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    const params = queryString.parse(props.location.search);
    if (params["?token"]) {
      setToken(params["?token"]);
    } else {
      setToken("");
    }
  }, [props.location.search]);

  const [form] = Form.useForm();

  const submit = () => {
    form.validateFields().then((values) => {
      recoverPassword({
        password: values.password,
        confirm_password: values.confirm_password,
        token: token,
      })
        .then((response) => {
          if (response.status === "success") {
            history.push(recoverPageUrlResult());
          } else {
            message.error(t("changePasswordError"));
          }
        })
        .catch((e) => {
          console.log("error:", e);
        });
    });
  };

  return (
    <div className={width <= 768 ? "recover-box-responsive" : "recover-box"}>
      <div className="image-container">
        <img alt="logo" src={logo} className="image" />
      </div>
      <Form name="normal_login" form={form}>
        <h2> {t("insertNewPassword")} </h2>
        {inputs(t).map((el) => {
          return (
            <Form.Item
              key={el.key}
              name={el.name}
              rules={
                el.key === "confirm_password"
                  ? [
                      ({ getFieldValue }: any) => ({
                        validator(value: any) {
                          if (
                            !value ||
                            getFieldValue("password") ===
                              getFieldValue("confirm_password")
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(t("passwordMatching"));
                        },
                      }),
                    ]
                  : el.key === "password"
                  ? [
                      ({ getFieldValue }: any) => ({
                        validator(value: any) {
                          if (
                            !value ||
                            (/.*\d.*/.test(getFieldValue("password")) &&
                              /.*[A-Z].*/.test(getFieldValue("password")) &&
                              /.*[a-z].*/.test(getFieldValue("password")) &&
                              /.*[~!].*/.test(getFieldValue("password")))
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(t("passwordRule"));
                        },
                      }),
                    ]
                  : undefined
              }
            >
              <Input
                size="large"
                type={el.type ?? "text"}
                prefix={el.icon}
                placeholder={el.label}
              />
            </Form.Item>
          );
        })}
        <div className="recover-btn-container">
          <Form.Item>
            <ButtonItem
              buttonType="primary"
              label={t("confirm")}
              buttonOnClick={submit}
            />
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default RecoverPassword;
