import { Card, Divider } from "antd";
import Title from "antd/es/typography/Title";
import { useTranslation } from "react-i18next";
import { Filters } from "../../../types/table/types";

type FilterSummaryProps = {
  filters: Filters[];
};

const FilterSummary: React.FC<FilterSummaryProps> = ({ filters }) => {
  const { t } = useTranslation();

  return (
    <Card
      style={{ minHeight: "290px", maxHeight: "290px" }}
      hoverable
      title={<Title level={5}>{t("filterSummary")}</Title>}
      bordered
      headStyle={{ maxHeight: "4vh" }}
      bodyStyle={{ maxHeight: "25vh", overflow: "auto" }}
    >
      {filters.map((el, index) => {
        if (el.value !== "") {
          return (
            <div key={index}>
              {el.key + ": " + el.value}
              <Divider />
            </div>
          );
        }
      })}
    </Card>
  );
};

export default FilterSummary;
