import {
  TableWithGetDataFunctionCell,
  ResponsiveTableColumnType,
} from "../../tableWithGetDataFunction";
import "../../../App.css";
import {
  checkModel,
  dateTimeType,
  linkType,
  statusDate,
  stringType,
} from "../../../utilities/utilities";

export const installationsListColumns: (
  linkFunction: (value: string) => void,
  t: any
) => ResponsiveTableColumnType[] = (linkFunction, t) => [
  {
    label: t("name"),
    name: "name",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <TableWithGetDataFunctionCell
          type={linkType}
          value={value}
          link={() => linkFunction(data.tableData[data.rowIndex].id)}
        />
      ),
    },
  },
  {
    label: t("model"),
    name: "model",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <TableWithGetDataFunctionCell
          type={stringType}
          value={checkModel(value || "")}
        />
      ),
    },
  },
  {
    label: t("description"),
    name: "description",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <TableWithGetDataFunctionCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("creationDate"),
    name: "created_at",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <TableWithGetDataFunctionCell type={dateTimeType} value={value} />
      ),
    },
  },
  {
    label: t("lastActivity"),
    name: "last_seen_on",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <TableWithGetDataFunctionCell type={statusDate} value={value} />
      ),
    },
  },
];

export const updateInstallationTableColumns: (
  linkFunction: (value: string) => void,
  t: any
) => ResponsiveTableColumnType[] = (linkFunction, t) => [
  ...installationsListColumns(linkFunction, t),
  {
    label: t("company"),
    name: "company",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <TableWithGetDataFunctionCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("customer"),
    name: "customer",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <TableWithGetDataFunctionCell type={stringType} value={value} />
      ),
    },
  },
];
