import "./RecoverPassword.css";
import "antd/dist/antd.css";
import { Result } from "antd";
import React, { useEffect, useState } from "react";
import ButtonItem from "../../ButtonItem";
import { useHistory } from "react-router";
import { loginPageUrl } from "../costants";
import { useTranslation } from "react-i18next";

type ResultRecoverPasswordProps = {};

const ResultRecoverPassword: React.FC<ResultRecoverPasswordProps> = () => {
  const { t } = useTranslation();

  document.body.style.backgroundColor = "#011529";
  const [width, setWidth] = useState<number>(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const history = useHistory();
  const submit = () => {
    history.push(loginPageUrl());
  };

  return (
    <>
      <h1 className="title"> Letomec cloud </h1>
      <div className={width <= 768 ? "recover-box-responsive" : "recover-box"}>
        <Result
          status="success"
          title={t("passwordChangedSuccessfully")}
          subTitle={t("logWithNewPassword")}
          extra={[
            <ButtonItem
              buttonType="primary"
              label={t("login")}
              buttonOnClick={submit}
            />,
          ]}
        />
      </div>
    </>
  );
};

export default ResultRecoverPassword;
