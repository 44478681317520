import "./charts/lineChart/LineChart.css";
import "../../../App.css";
import React, { useCallback } from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import ButtonItem from "../../ButtonItem";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Col, message, Row, Tabs } from "antd";
import { SessionInfoType } from "../../../api/requests/reportsService";
import FilterSummary from "./summaryCards/FilterSummary";
import InstallationSummary from "./summaryCards/InstallationSummary";
import { Installation } from "../../../api/requests/installationsService";
import { Filters } from "../../types/table/types";
import { testTabColumns } from "./inputs";
import TableWithGetDataFunction from "../../tableWithGetDataFunction";
import { useTranslation } from "react-i18next";

const { TabPane } = Tabs;

type HandleSubmitTestProps = {
  width: number;
  dataResult: any[];
  url: string;
  summary: SessionInfoType[];
  resetSearch: () => void;
  installation: Installation;
  filters: Filters[];
};

const HandleSubmitTest: React.FC<HandleSubmitTestProps> = ({
  width,
  dataResult,
  url,
  summary,
  resetSearch,
  installation,
  filters,
}) => {
  const { t } = useTranslation();

  const colors = ["#8884D8", "#008000", "#FF6347", "#1890ff", "#eb2f96"];
  let charts: JSX.Element[] = [];

  Object.entries(dataResult).forEach(([key, value], index) => {
    if (value && value.length >= 0) {
      value.map((el: any) => {
        el.value = Number(el.value);
      });
      charts = [
        ...charts,
        <Line
          isAnimationActive={false}
          type="natural"
          dataKey="value"
          data={value}
          name={key}
          stroke={colors[index]}
          key={`result_${key}`}
          yAxisId={1}
        />,
      ];
    }
  });

  const printDocument = () => {
    const input = document.getElementById("divToPrint");
    if (input) {
      window.scrollTo(0, 0);
      html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");

        var imgWidth = 210;
        var pageHeight = 295;
        var imgHeight = (canvas.height * imgWidth) / canvas.width;
        var heightLeft = imgHeight;

        var doc = new jsPDF("p", "mm");
        var position = 10;

        doc.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
        heightLeft = heightLeft - pageHeight;
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
          heightLeft = heightLeft - pageHeight;
        }
        doc.save("download.pdf");
      });
    } else {
      message.error(t("errorDownloadPdf"));
    }
  };

  const downloadFile = useCallback(() => {
    // Create a link element
    const link = document.createElement("a");

    // Set link's href to point to the Blob URL
    link.href = url;

    // Append link to the body
    document.body.appendChild(link);

    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );

    // Remove link from body
    document.body.removeChild(link);
  }, [url]);

  return (
    <>
      <Row className="cards">
        <Col xs={24} xl={10} className="card-responsive">
          <InstallationSummary selected_installation={installation} />
        </Col>
        <Col xs={24} xl={10} className="card-responsive">
          <FilterSummary filters={filters} />
        </Col>
      </Row>
      <div className="btn-container" key={"btn_div"}>
        <ButtonItem
          buttonType="primary"
          label={t("downloadCsv")}
          buttonOnClick={downloadFile}
        />
        <ButtonItem
          buttonType="primary"
          label={t("downloadPdf")}
          buttonOnClick={printDocument}
        />
        <ButtonItem label={t("editSelection")} buttonOnClick={resetSearch} />
      </div>
      <div className={width <= 575 ? "chartStyleResponsive" : ""}>
        <Tabs defaultActiveKey="chart">
          <TabPane tab={t("chart")} key="chart">
            <ResponsiveContainer width="100%" maxHeight={280} height={280}>
              <LineChart>
                <CartesianGrid />
                <XAxis
                  dataKey={"ts"}
                  name="Data"
                  //tickFormatter={dateFormatter}
                  allowDuplicatedCategory={false}
                />
                <YAxis
                  type="number"
                  domain={["dataMin - 5", "dataMax + 5"]}
                  yAxisId={1}
                />
                <Tooltip
                  wrapperStyle={{ zIndex: 101 }}
                  formatter={(value: string, name: string) => {
                    return `${value}`;
                  }}
                  labelFormatter={(value: string) => {
                    return (
                      <div key="tooltip">
                        {value}
                        <br />
                        {summary.map((el, index) => {
                          if (el.test_date === value) {
                            return (
                              <div key={index}>
                                {`${t("sessionId")}: ${el.session_id}`}
                                <br />
                                {`${t("date")}: ${el.test_date}`}
                                <br />
                                {`${t("testType")}: ${el.test_type}`}
                                <br />
                                {el.material && el.material.length > 0 ? (
                                  <>
                                    {`${t("material")}: ${el.material}`} <br />
                                  </>
                                ) : null}
                                {el.supplier && el.supplier.length > 0 ? (
                                  <>
                                    {`${t("specimenSupplier")}: ${
                                      el?.supplier
                                    }`}
                                    <br />
                                  </>
                                ) : null}
                                {el.thickness && el.thickness.length > 0 ? (
                                  <>
                                    {`${t("specimenThickness")}: ${
                                      el.thickness
                                    }`}
                                    <br />
                                  </>
                                ) : null}
                                {el.bath_supplier &&
                                el.bath_supplier.length > 0 ? (
                                  <>
                                    {`${t("bathSupplier")}: ${
                                      el.bath_supplier
                                    }`}
                                    <br />
                                  </>
                                ) : null}
                                {el.bath_type && el.bath_type.length > 0 ? (
                                  <>
                                    {`${t("bathType")}: ${el.bath_type}`} <br />
                                  </>
                                ) : null}
                                {el.bath_ph && el.bath_ph.length > 0 ? (
                                  <>
                                    {`${t("bathPh")}: ${el.bath_ph}`} <br />
                                  </>
                                ) : null}
                                {el.edc && el.edc.length > 0 ? (
                                  <>
                                    {`${t("edc")}: ${el.edc}`} <br />
                                  </>
                                ) : null}
                                {el.electronic_voltage &&
                                el.electronic_voltage.length > 0 ? (
                                  <>
                                    {`${t("electronicVoltage")}: ${
                                      el.electronic_voltage
                                    }`}
                                    <br />
                                  </>
                                ) : null}
                                {el.bup && el.bup.length > 0 ? (
                                  <>
                                    {`${t("bup")}: ${el.bup}`} <br />
                                  </>
                                ) : null}
                                {el.sample_type && el.sample_type.length > 0 ? (
                                  <>
                                    {`${t("sampleType")}: ${el.sample_type}`}{" "}
                                    <br />
                                  </>
                                ) : null}
                                {el.sample_supplier &&
                                el.sample_supplier.length > 0 ? (
                                  <>
                                    {`${t("sampleSupplier")}: ${
                                      el.sample_supplier
                                    }`}
                                    <br />
                                  </>
                                ) : null}
                                {el.steel_thickness &&
                                el.steel_thickness.length > 0 ? (
                                  <>
                                    {`${t("steelThickness")}: ${
                                      el.steel_thickness
                                    }`}
                                    <br />
                                  </>
                                ) : null}
                                {el.specimen_material &&
                                el.specimen_material.length > 0 ? (
                                  <>
                                    {`${t("specimenMaterial")}: ${
                                      el.specimen_material
                                    }`}
                                    <br />
                                  </>
                                ) : null}
                                {el.coating_thickness &&
                                el.coating_thickness.length > 0 ? (
                                  <>
                                    {`${t("coatingThickness")}: ${
                                      el.coating_thickness
                                    }`}
                                    <br />
                                  </>
                                ) : null}
                              </div>
                            );
                          }
                        })}
                      </div>
                    );
                  }}
                />
                {charts}
                <Legend className="legend" />
              </LineChart>
            </ResponsiveContainer>
          </TabPane>
          <TabPane tab={t("table")} key="table">
            <Tabs>
              {Object.keys(dataResult).map((el, index) => {
                return (
                  <TabPane tab={el} key={index}>
                    <TableWithGetDataFunction
                      icon={false}
                      data={(dataResult as any)[el]}
                      columns={testTabColumns(t)}
                    />
                  </TabPane>
                );
              })}
            </Tabs>
          </TabPane>
        </Tabs>
      </div>
    </>
  );
};
export default HandleSubmitTest;
