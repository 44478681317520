import { connect } from 'react-redux'
import { Store } from '../../../reducers'
import {setDataStartDate, setPeriod, startUpdateChartData} from '../../../actions/lineChart'
import {bindActionCreators} from "redux";
import InstallationPage from '../../../components/pages/installation/InstallationPage';

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators(
        {
           setDataStartDate,
           setPeriod,
           startUpdateChartData,
           clearDataInterval: () => dispatch({type: 'CLEAR_INTERVAL'})
        },
        dispatch,
    );
}

const mapStateToProps = (state: Store) => {
    return {
        data_chart: state.chart
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InstallationPage);