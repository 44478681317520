import { connect } from 'react-redux'
import CustomChart from '../../../../../components/pages/installation/charts/lineChart/CustomChart'
import { Store } from '../../../../../reducers'
import { ZoomUpdates } from '../../../../../components/types/timeseries/types';
import {Dispatch} from "react";
import {Action} from "../../../../../actions";
import {SET_ZOOM} from "../../../../../actions/lineChart";

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        SetZoom: (type: SET_ZOOM ,ref: ZoomUpdates, key: string) => dispatch({type:type, zoomUpd:ref, key: key}),
        SetAggregationView: (aggregations: string[], key: string) => dispatch({type:'SET_AGGREGATIONS', aggregations: aggregations, key: key})
    }
}

const mapStateToProps = (state: Store) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomChart);