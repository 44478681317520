import "./charts/lineChart/LineChart.css";
import "../../../App.css";
import React, { useCallback, useEffect, useState } from "react";
import { Checkbox, Col, Row } from "antd";
import { Installation } from "../../../api/requests/installationsService";
import ButtonItem from "../../ButtonItem";
import TableWithGetDataFunction from "../../tableWithGetDataFunction";
import {
  helios2GalvModel,
  helios2GalvTable,
  helios2Model,
  helios2Table,
  helios3Model,
  helios3Table,
  helios4HotProbeModel,
  helios4HotProbeTable,
} from "./costant";
import { SessionInfoType } from "../../../api/requests/reportsService";
import { Filters } from "../../types/table/types";
import {
  GetTestData,
  GetTestResult,
} from "../../../api/services/reportsService";
import HandleSubmitResult from "./HandleSubmitResult";
import HandleSubmitTest from "./HandleSubmitTest";
import FilterSummary from "./summaryCards/FilterSummary";
import InstallationSummary from "./summaryCards/InstallationSummary";
import { useTranslation } from "react-i18next";

type TestComparerResultTableProps = {
  installation: Installation;
  filters: Filters[];
  rows: SessionInfoType[];
  resetSearch: () => void;
};

type resultType = "handleSubmitResult" | "handleSubmitTest";

const TestComparerResultTable: React.FC<TestComparerResultTableProps> = ({
  installation,
  filters,
  rows,
  resetSearch,
}) => {
  const { t } = useTranslation();

  const [countLines, setCountLines] = useState<any[]>([]);
  const [url, setUrl] = useState<string>("");
  const [dataResult, setDataResult] = useState<any[] | null>(null);
  const [resultType, setResultType] = useState<resultType | null>(null);
  const [summary, setSummary] = useState<SessionInfoType[]>([]);

  const [width, setWidth] = useState<number>(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const resetResult = useCallback(() => {
    setResultType(null);
  }, []);

  const handleSubmitResult = () => {
    let tmp: any[] = [];
    rows.map((el) => {
      if (countLines.includes(el.session_id)) {
        tmp.push({
          data: el?.test_date || "",
          value: Number(el?.test_result) || 0,
        });
      }
    });
    let sessions_id: any[] = [];
    let tmp_summary: SessionInfoType[] = [];
    rows.map((el) => {
      if (countLines.includes(el.session_id)) {
        sessions_id.push(el.session_id);
        tmp_summary.push(el);
      }
    });
    GetTestResult(installation.id, sessions_id).then((res) => {
      if (res && !res.err) {
        setSummary(tmp_summary);
        setResultType("handleSubmitResult");
        setUrl(res.csv_url);
        setDataResult(tmp);
      }
    });
  };

  const handleSubmitTest = () => {
    let sessions_id: any[] = [];
    let tmp_summary: SessionInfoType[] = [];
    rows.map((el) => {
      if (countLines.includes(el.session_id)) {
        sessions_id.push(el.session_id);
        tmp_summary.push(el);
      }
    });
    GetTestData(installation.id, sessions_id).then((res) => {
      if (res && !res.err) {
        setSummary(tmp_summary);
        setResultType("handleSubmitTest");
        setUrl(res.csv_url);
        setDataResult(res.testsessions_data);
      }
    });
  };

  let tableFields: any[];
  switch (installation.model) {
    case helios2Model:
      tableFields = helios2Table(countLines, setCountLines, t);
      break;
    case helios2GalvModel:
      tableFields = helios2GalvTable(countLines, setCountLines, t);
      break;
    case helios3Model:
      tableFields = helios3Table(countLines, setCountLines, t);
      break;
    case helios4HotProbeModel:
      tableFields = helios4HotProbeTable(countLines, setCountLines, t);
      break;
    default:
      tableFields = [];
      break;
  }

  if (!resultType) {
    return (
      <>
        <Row className="cards">
          <Col xs={24} xl={10} className="card-responsive">
            <InstallationSummary selected_installation={installation} />
          </Col>
          <Col xs={24} xl={10} className="card-responsive">
            <FilterSummary filters={filters} />
          </Col>
        </Row>
        <div className="div-btn" key={"btn_div"}>
          <ButtonItem
            buttonType="primary"
            label={t("compareResults")}
            buttonOnClick={handleSubmitResult}
            disabled={Boolean(countLines.length <= 0)}
          />
          <ButtonItem
            buttonType="primary"
            label={t("compareTests")}
            buttonOnClick={handleSubmitTest}
            disabled={Boolean(countLines.length <= 0 || countLines.length > 5)}
          />
          <ButtonItem label={t("changeFilters")} buttonOnClick={resetSearch} />
        </div>
        <div className="selectAll selectAllResponsive">
          {t("selectAll")}
          <Checkbox
            key="selectAll"
            onChange={(event) => {
              if (event.target.checked) {
                rows.map((el) => {
                  let tmp = countLines;
                  tmp.push(el.session_id);
                  setCountLines(tmp);
                });
              } else {
                setCountLines([]);
              }
            }}
          />
        </div>
        <TableWithGetDataFunction columns={tableFields} data={rows} />
      </>
    );
  } else {
    switch (resultType) {
      case "handleSubmitResult":
        return (
          <HandleSubmitResult
            width={width}
            dataResult={dataResult ?? []}
            url={url}
            resetSearch={resetResult}
            installation={installation}
            filters={filters}
            summary={summary}
          />
        );
      case "handleSubmitTest":
        return (
          <HandleSubmitTest
            width={width}
            dataResult={dataResult ?? []}
            url={url}
            resetSearch={resetResult}
            installation={installation}
            filters={filters}
            summary={summary}
          />
        );
      default:
        return <></>;
    }
  }
};
export default TestComparerResultTable;
