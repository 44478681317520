import "./summary.css";
import React from "react";
import { Card, Divider } from "antd";
import { Installation } from "../../../../api/requests/installationsService";
import moment from "moment";
import Title from "antd/es/typography/Title";
import { useTranslation } from "react-i18next";
import { checkModel } from "../../../../utilities/utilities";

type InstallationSummaryProps = {
  selected_installation: Installation | null;
};

const InstallationSummary: React.FC<InstallationSummaryProps> = ({
  selected_installation,
}) => {
  const { t } = useTranslation();

  const dateFormatter = (date: string) => {
    return moment(date).format("DD/MM/YYYY HH:mm");
  };

  return (
    <Card
      style={{ minHeight: "290px", maxHeight: "290px" }}
      hoverable
      title={<Title level={5}>{t("installation")}</Title>}
      headStyle={{ maxHeight: "4vh" }}
      bodyStyle={{ maxHeight: "25vh", overflow: "auto" }}
    >
      <div className={"variable-container"}>
        <p> {t("name")}: </p>
        <p> {selected_installation?.name || ""} </p>
      </div>
      <Divider />

      <div className="variable-container">
        <p> {t("model")}: </p>
        <p>{checkModel(selected_installation?.model || "")} </p>
      </div>
      <Divider />

      <div className="variable-container">
        <p> {t("creationDate")}: </p>
        <p> {dateFormatter(selected_installation?.created_at || "")} </p>
      </div>
    </Card>
  );
};
export default InstallationSummary;
