import React from "react";
import "../../../App.css";
import { updateInstallationTableColumns } from "./inputs";
import { GetAllInstallations } from "../../../api/services/installationsService";
import CustomTitle from "../../CustomTitle";
import { useHistory } from "react-router";
import { updateInstallationUrl } from "../costants";
import TableWithGetDataFunction from "../../tableWithGetDataFunction";
import { useTranslation } from "react-i18next";

type InstallationListPageProps = {};

const InstallationsListPage: React.FC<InstallationListPageProps> = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const linkFunction: (value: string) => void = (value: string) =>
    history.push(updateInstallationUrl(value));

  return (
    <>
      <CustomTitle title={t("updateInstallation")} />
      <TableWithGetDataFunction
        columns={updateInstallationTableColumns(linkFunction, t)}
        getPageAndSortedData={GetAllInstallations}
        defaultSort="name"
        responseDataName="installations"
      />
    </>
  );
};

export default InstallationsListPage;
