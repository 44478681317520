import "./DeviceConnection.css";
import ButtonItem from "../../ButtonItem";
import ButtonConfItem from "../../ButtonConfItem";
import { Form, Input, Progress, Result, Select } from "antd";
import React, { useCallback, useState } from "react";
import TitleWithBack from "../../../containers/TitleWithBack";

import {
  CreateIdentity,
  DeleteIdentity,
  GetIdentity,
} from "../../../api/services/installationsService";
import { useTranslation } from "react-i18next";

const { Option } = Select;

type DeviceConnectionConfigurationProps = {
  installation_id: string;
};

const DeviceConnectionConfiguration: React.FC<
  DeviceConnectionConfigurationProps
> = ({ installation_id }) => {
  const { t } = useTranslation();

  const [formItem, setFormItem] = useState("wifi");
  const [form] = Form.useForm();
  const [result, setResult] = useState<boolean | null>(null);
  const [progress, setProgress] = useState<boolean>(false);
  const [percent, setPercent] = useState<number>(0);

  const connect = useCallback(async (sid: string, password: string) => {
    setProgress(true);
    let mounted: boolean = true;
    let checkDevice: boolean = true;
    let userAgent = navigator.userAgent.toLowerCase();
    if (mounted && userAgent.indexOf(" electron/") > -1) {
      let board: string = "";
      let uid: string = "";
      let phys_id: string = "";
      let provision_prepare: boolean = false;
      window.api.receive("ztc_discover_result", (devices: any[]) => {
        if (mounted) {
          if (devices.length > 0) {
            board = devices[0].board;
            uid = devices[0].uid;
            setPercent(10);
            window.api.send("ztc_phys_id", uid);
          } else {
            setResult(false);
          }
        }
      });
      window.api.receive("ztc_phys_id_result", async (result: any[]) => {
        if (mounted) {
          await window.api.send("ztc_check_discover", [board, uid]);
          if (checkDevice) {
            if (board === "4zerobox_v9") {
              window.api.send("ztc_provision_prepare", uid);
            } else {
              phys_id = String(result);
              window.api.send("ztc_provision_prepare", phys_id);
            }
            setPercent(20);
          } else {
            setResult(false);
          }
        }
      });
      window.api.receive(
        "ztc_provision_prepare_result",
        async (result: any[]) => {
          if (mounted) {
            await window.api.send("ztc_check_discover", [board, uid]);
            if (checkDevice) {
              provision_prepare = Boolean(result);
              if (provision_prepare) {
                setPercent(30);
                if (board === "4zerobox_v9") {
                  window.api.send("ztc_provision_command", uid);
                } else {
                  window.api.send("ztc_provision_command", phys_id);
                }
              } else {
                setResult(false);
              }
            } else {
              setResult(false);
            }
          }
        }
      );
      window.api.receive("ztc_configure_result", async (result: any[]) => {
        if (mounted) {
          await window.api.send("ztc_check_discover", [board, uid]);
          if (checkDevice) {
            if (!String(result).includes("error")) {
              setPercent(60);
              window.api.send("ztc_compile");
            } else {
              setResult(false);
            }
          } else {
            setResult(false);
          }
        }
      });
      window.api.receive("ztc_compile_result", async (result: any[]) => {
        if (mounted) {
          await window.api.send("ztc_check_discover", [board, uid]);
          if (checkDevice) {
            if (String(result).includes("Compilation Ok")) {
              setPercent(80);
              window.api.send("ztc_link");
            } else {
              setResult(false);
            }
          } else {
            setResult(false);
          }
        }
      });
      window.api.receive("ztc_link_result", async (result: any[]) => {
        if (mounted) {
          await window.api.send("ztc_check_discover", [board, uid]);
          if (checkDevice) {
            if (!String(result).includes("error")) {
              setPercent(90);
              window.api.send("ztc_burn");
            } else {
              setResult(false);
            }
          } else {
            setResult(false);
          }
        }
      });
      window.api.receive("ztc_burn_result", async (result: any[]) => {
        if (mounted) {
          await window.api.send("ztc_check_discover", [board, uid]);
          if (checkDevice) {
            if (!String(result).includes("error")) {
              window.api.send("ztc_console");
              setPercent(100);
              setResult(true);
            } else {
              setResult(false);
            }
          } else {
            setResult(false);
          }
        }
      });
      window.api.receive(
        "ztc_provision_command_result",
        async (result: any[]) => {
          const bundle: string = String(result);
          if (mounted && bundle.startsWith("sn")) {
            await window.api.send("ztc_check_discover", [board, uid]);
            if (checkDevice) {
              setPercent(40);
              GetIdentity(installation_id).then((res) => {
                if (res && !res.err) {
                  if (res?.identities !== undefined) {
                    //sullo ZDM c'è già una identity
                    let old_dcn = res.identities[0]?.dcn;
                    if (bundle.includes(old_dcn)) {
                      //non si vuole sostituire il device fisico ma solo aggiornare il firmware
                      setPercent(50);
                      window.api.send("ztc_configure", [
                        board,
                        uid,
                        sid,
                        password,
                      ]);
                    } else {
                      //si vuole sostituire il device fisico
                      DeleteIdentity({
                        installation_id: installation_id,
                        dcn: old_dcn,
                      }).then((res) => {
                        if (res && !res.err) {
                          setPercent(45);
                          CreateIdentity(
                            { phys_id: phys_id, bundle: bundle },
                            installation_id
                          ).then((res: any) => {
                            if (res && !res.err) {
                              setPercent(50);
                              window.api.send("ztc_configure", [
                                board,
                                uid,
                                sid,
                                password,
                              ]);
                            } else {
                              setResult(false);
                            }
                          });
                        } else {
                          setResult(false);
                        }
                      });
                    }
                  } else {
                    //sullo ZDM non c'è nessuna identity quindi è il primo collegamento
                    CreateIdentity(
                      { phys_id: phys_id, bundle: String(result) },
                      installation_id
                    ).then((res: any) => {
                      if (res && !res.err) {
                        setPercent(50);
                        window.api.send("ztc_configure", [
                          board,
                          uid,
                          sid,
                          password,
                        ]);
                      } else {
                        setResult(false);
                      }
                    });
                  }
                } else {
                  setResult(false);
                }
              });
            } else {
              setResult(false);
            }
          } else {
            setResult(false);
          }
        }
      );
      window.api.receive("ztc_check_discover_result", (result: any) => {
        checkDevice = Boolean(result);
      });
      if (mounted) {
        // Electron-specific code
        window.api.send("ztc_discover", "");
      }
    }
    return () => {
      mounted = false;
    };
  }, []);

  const submit = () => {
    form.validateFields().then(async (values) => {
      connect(values.sid, values.password);
    });
  };

  return (
    <>
      <TitleWithBack title={t("setupConnection")} key={"add_connection"} />
      <div className="my-connection-container">
        <Form layout="vertical" key={1} name="connection_panel" form={form}>
          <Form.Item
            label="Seleziona la tipologia di connettività"
            name="connection_type"
            initialValue="wifi"
          >
            <Select
              placeholder="..."
              onChange={(value: string) => {
                setFormItem(value);
              }}
            >
              <Option value="wifi" key="1">
                WiFi
              </Option>
              <Option value="gsm" key="2">
                GSM
              </Option>
            </Select>
          </Form.Item>
          {formItem === "wifi" ? (
            <>
              <Form.Item
                label={"Nome rete"}
                name={"sid"}
                rules={[{ required: true, message: "Inserire Nome rete!" }]}
              >
                <Input placeholder="Nome rete" type="text" />
              </Form.Item>
              <Form.Item
                label={"Password"}
                name={"password"}
                rules={[{ required: true, message: "Inserire Password !" }]}
              >
                <Input placeholder="Password" type="password" />
              </Form.Item>
            </>
          ) : (
            <Form.Item
              label={"Seleziona l'operatore telefonico'"}
              name={"operator"}
            >
              <Select
                placeholder={"..."}
                onChange={(value: string) => {
                  setFormItem(value);
                }}
              >
                <Option value="vodafone" key="1">
                  Vodafone
                </Option>
                <Option value="tim" key="2">
                  TIM
                </Option>
                <Option value="wind" key="3">
                  Wind
                </Option>
                <Option value="tre" key="2">
                  Tre
                </Option>
              </Select>
            </Form.Item>
          )}
          <div className="btn-container">
            <ButtonConfItem
              buttonLabel="Reset"
              buttonOnConfirm={() => {
                form.resetFields();
              }}
              buttonOnCancel={() => {}}
              questionLabel="Il contenuto di tutti i campi sarà cancellato, sei sicuro?"
            />
            <ButtonItem
              buttonType="primary"
              label="Invio"
              buttonOnClick={submit}
            />
          </div>
        </Form>
      </div>
      {progress ? (
        <div className="progress">
          Stiamo aggiornando il device con le credenziali inserite, l'operazione
          potrebbe richiedere qualche minuto.
          <Progress
            className="progress"
            strokeColor={{ from: "#108ee9", to: "#87d068" }}
            percent={percent}
            status="active"
          />
          {result === true ? (
            <Result
              status="success"
              title="L'operazione è stata completata con successo"
            />
          ) : result === false ? (
            <Result
              status="error"
              title="L'operazione non è andata a buon fine."
            />
          ) : null}
        </div>
      ) : null}
    </>
  );
};

export default DeviceConnectionConfiguration;
