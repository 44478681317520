import { InputType } from "../../types/form/types";
import {
  confirmPasswordRules,
  emailRules,
  passwordRules,
} from "../../types/form/rules";

export const userFields: (t: any) => InputType[] = (t: any) => [
  {
    key: "name",
    name: "name",
    label: t("name"),
    rules: [{ required: true, message: "Inserire nome!" }],
  },
  {
    key: "email",
    name: "email",
    label: t("email"),
    type: "email",
    rules: emailRules("email", t),
  },
  {
    key: "password",
    name: "password",
    label: t("password"),
    type: "password",
    rules: passwordRules(t),
  },
  {
    key: "confirm_password",
    name: "confirm_password",
    label: t("confirmPassword"),
    type: "password",
    rules: confirmPasswordRules(t),
  },
  {
    key: "customer_phone",
    name: "customer_phone",
    label: t("phone"),
    path: "phone",
    type: "number",
    rules: [{ required: true, message: "Inserire telefono!" }],
  },
  {
    key: "customer_address",
    name: "customer_address",
    label: t("address"),
    path: "address",
    rules: [{ required: true, message: "Inserire indirizzo!" }],
  },
  {
    key: "customer_city",
    name: "customer_city",
    label: t("city"),
    path: "city",
    rules: [{ required: true, message: "Inserire città!" }],
  },
  {
    key: "customer_province",
    name: "customer_province",
    label: t("province"),
    path: "province",
    rules: [{ required: true, message: "Inserire provincia!" }],
  },
  {
    key: "customer_country",
    name: "customer_country",
    label: t("country"),
    path: "country",
    type: "select",
    rules: [{ required: true, message: "Selezionare Paese!" }],
  },
  {
    key: "customer_zip_code",
    name: "customer_zip_code",
    label: t("zipCode"),
    path: "zip_code",
    rules: [{ required: true, message: "Inserire CAP!" }],
  },
];
