import {Dispatch} from "react";
import {Action} from "../actions";
import {Store} from "../reducers";
import {connect} from "react-redux";
import TitleWithBack from "../components/TitleWithBack";

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
    }
}

const mapStateToProps = (state: Store) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TitleWithBack)