import { GetProfileUrl, UsersServiceUrl } from "../constants";
import {
  AddUserRequest,
  GetUserRequest,
  UpdateUserRequest,
} from "../requests/userService";
import {
  methodGet,
  methodPost,
  methodPut,
  verticalFetch,
} from "./httpRequests";
import { rowsPerTablePage } from "../../utilities/utilities";

//Create a new user
export const AddUser = (r: AddUserRequest) => {
  return verticalFetch(methodPost, UsersServiceUrl, JSON.stringify(r));
};

//Get a user
export const GetUser = (r: GetUserRequest) => {
  return verticalFetch(methodGet, `${UsersServiceUrl}/${r.user_id}`);
};

//Update a user entity
export const UpdateUser = (r: UpdateUserRequest) => {
  return verticalFetch(
    methodPut,
    `${UsersServiceUrl}/${r.user_id}`,
    JSON.stringify(r)
  );
};

//Get the list of all customers
export const GetCustomers = (page: string, sort: string, search: string) => {
  return verticalFetch(
    methodGet,
    `${UsersServiceUrl}/customers?page=${page}&rows=${rowsPerTablePage}&sort=${sort}&search=${search}`
  );
};

//Get user information
export const GetUserProfile = () => {
  return verticalFetch(methodGet, GetProfileUrl);
};
