import React from "react";
import "../../navbar/Navbar.css";
import { customersListColumns } from "./input";
import "../../../App.css";
import "./UsersListTable.css";
import { GetCustomers } from "../../../api/services/userService";
import CustomTitle from "../../CustomTitle";
import { useHistory } from "react-router-dom";
import TableWithGetDataFunction from "../../tableWithGetDataFunction";
import { updateUserPageUrl } from "../costants";
import { useTranslation } from "react-i18next";

const UsersListTable: React.FC = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const linkFunction: (value: string) => void = (value: string) => {
    history.push(updateUserPageUrl(value));
  };

  return (
    <>
      <CustomTitle title={t("updateUser")} />
      <TableWithGetDataFunction
        columns={customersListColumns(linkFunction, t)}
        getPageAndSortedData={GetCustomers}
        responseDataName="customers"
        defaultSort="name"
      />
    </>
  );
};

export default UsersListTable;
