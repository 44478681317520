import { Subscription } from "../../../api/requests/subscription";
import {
  TableWithGetDataFunctionCell,
  ResponsiveTableColumnType,
} from "../../tableWithGetDataFunction";
import "../../../App.css";
import React from "react";
import {
  dateTimeType,
  linkType,
  stringType,
} from "../../../utilities/utilities";
import { roles } from "../costants";

export const subscriptionsTableColumns: (
  link: (v: string) => void,
  role: string,
  updSubState: (record: Subscription) => React.ReactNode,
  t: any
) => ResponsiveTableColumnType[] = (linkFunction, role, updSubState, t) => [
  {
    label: t("company"),
    name: "company_info",
    options: {
      sort: false,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <TableWithGetDataFunctionCell
          type={linkType}
          value={data.tableData[data.rowIndex].company_info.name}
          link={() => {
            linkFunction(data.tableData[data.rowIndex].id);
          }}
        />
      ),
    },
  },
  {
    label: t("sdi"),
    name: "company_info",
    options: {
      sort: false,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <TableWithGetDataFunctionCell
          type={stringType}
          value={data.tableData[data.rowIndex].company_info.sdi}
        />
      ),
    },
  },
  {
    label: t("pec"),
    name: "company_info",
    options: {
      sort: false,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <TableWithGetDataFunctionCell
          type={stringType}
          value={data.tableData[data.rowIndex].company_info.pec}
        />
      ),
    },
  },
  {
    label: t("vat"),
    name: "company_info",
    options: {
      sort: false,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <TableWithGetDataFunctionCell
          type={stringType}
          value={data.tableData[data.rowIndex].company_info.vat_number}
        />
      ),
    },
  },
  {
    label: t("phone"),
    name: "company_info",
    options: {
      sort: false,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <TableWithGetDataFunctionCell
          type={stringType}
          value={data.tableData[data.rowIndex].company_info.phone}
        />
      ),
    },
  },
  {
    label: t("address"),
    name: "company_info",
    options: {
      sort: false,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <TableWithGetDataFunctionCell
          type={stringType}
          value={`${data.tableData[data.rowIndex].company_info.address}, ${
            data.tableData[data.rowIndex].company_info.city
          }, ${data.tableData[data.rowIndex].company_info.province}`}
        />
      ),
    },
  },
  {
    label: t("zipCode"),
    type: "string",
    name: "company_info",
    options: {
      sort: false,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <TableWithGetDataFunctionCell
          type={stringType}
          value={data.tableData[data.rowIndex].company_info.zip_code}
        />
      ),
    },
  },
  {
    label: t("expiryDate"),
    name: "expiry_date",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <TableWithGetDataFunctionCell type={dateTimeType} value={value} />
      ),
    },
  },
  ...statusIfAdmin(role, updSubState, t),
];

const statusIfAdmin = (
  role: string,
  updSubState: (record: Subscription) => React.ReactNode,
  t: any
) => {
  if (role === roles.admin) {
    return [
      {
        label: t("status"),
        name: "",
        options: {
          sort: true,
          filter: false,
          customBodyRender: (value: string, data: any) => (
            <div className="cell">
              {updSubState(data.tableData[data.rowIndex])}
            </div>
          ),
        },
      },
    ];
  }
  return [];
};
