import "./Navbar.css";
import { adminMenuItems } from "./adminMenuItems";
import { customerMenuItems } from "./customerMenuItems";
import React, { useEffect, useState } from "react";
import { GetUserProfile } from "../../api/services/userService";
import { MenuType } from "../types/navbar/types";
import { useHistory } from "react-router-dom";
import { roles } from "../pages/costants";
import logo from "../../logo/Logo_LETOMEC_Orizzontale_4.png";
import { useStyles } from "../../constants/muiStyles";
import {
  Collapse,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

type NavbarProps = {
  setNavbarIsCollapsed: (collapsed: boolean) => void;
  setRole: (role: string) => void;
  navbar_collapsed: boolean;
  panel_open: boolean;
  selected: string;
  setItem: (s: string) => void;
  setPanelIsOpen: (o: boolean) => void;
};

const Navbar: React.FC<NavbarProps> = ({
  setNavbarIsCollapsed,
  setRole,
  navbar_collapsed,
  panel_open,
  selected,
  setItem,
  setPanelIsOpen,
}) => {
  const { t, i18n } = useTranslation();
  const [menuItem, setMenuItem] = useState<MenuType[]>([]);
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const classes = useStyles();

  useEffect(() => {
    GetUserProfile().then((res) => {
      if (res && res.user && res.user.roles && res.user.roles.length > 0) {
        const role: string = res["user"]["roles"][0];
        if (role.includes(roles.admin)) {
          setRole(roles.admin);
          setMenuItem(adminMenuItems(t));
        } else if (role.includes(roles.customer)) {
          setRole(roles.customer);
          setMenuItem(customerMenuItems(t));
        }
      }
    });
  }, [i18n.language]);

  return (
    <Drawer
      key="drawer-navbar"
      onClose={() => setNavbarIsCollapsed(true)}
      open={isMobile ? !navbar_collapsed : true}
      variant={!isMobile ? "persistent" : undefined}
    >
      <div className="logo">
        <img src={logo} alt="logo" className="image" />
      </div>
      <List key="main_list" className={classes.drawer}>
        {menuItem.map((menuItem: MenuType) => (
          <>
            <ListItem
              button
              key={menuItem.key}
              onClick={() => {
                if (menuItem?.link) {
                  history.push(menuItem.link());
                  setNavbarIsCollapsed(true);
                }
                if (menuItem.elements.length > 0) {
                  setPanelIsOpen(!panel_open);
                } else {
                  setItem(menuItem.key);
                }
              }}
              selected={selected === menuItem.key}
            >
              <ListItemIcon key={`${menuItem.key}_icon`}>
                {menuItem.icon}
              </ListItemIcon>
              <ListItemText
                key={`${menuItem.key}_label`}
                primary={menuItem.label}
              />
              {menuItem.elements.length > 0 ? (
                panel_open ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )
              ) : null}
            </ListItem>
            {menuItem.elements.length > 0 ? (
              <Collapse
                key={`${menuItem.key}_collapse`}
                in={panel_open}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding key={`secondary_list`}>
                  {menuItem.elements.map((item) => (
                    <ListItem
                      button
                      key={item.key}
                      selected={selected === item.key}
                      onClick={() => {
                        setItem(item.key);
                        if (item?.link) {
                          history.push(item.link());
                          setNavbarIsCollapsed(true);
                        }
                      }}
                    >
                      <ListItemText
                        key={`${menuItem.key}_iconosaod`}
                        primary={item.label}
                      />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            ) : null}
          </>
        ))}
      </List>
    </Drawer>
  );
};

export default Navbar;
