import { MenuType } from "../types/navbar/types";
import {
  LineChartOutlined,
  HistoryOutlined,
  FileSearchOutlined,
} from "@ant-design/icons";
import {
  subscriptionsListToDataHistoryPageUrl,
  subscriptionsListToInstallationPageUrl,
  subscriptionsListToReportsPageUrl,
} from "../pages/costants";

export const customerMenuItems: (t: any) => MenuType[] = (t: any) => [
  {
    key: "data_real_time",
    label: t("dataAnalysis"),
    icon: <LineChartOutlined />,
    elements: [],
    link: subscriptionsListToInstallationPageUrl,
  },
  {
    key: "data_history",
    label: t("dataHistory"),
    icon: <HistoryOutlined />,
    elements: [],
    link: subscriptionsListToDataHistoryPageUrl,
  },
  {
    key: "reports",
    label: t("reports"),
    icon: <FileSearchOutlined />,
    elements: [],
    link: subscriptionsListToReportsPageUrl,
  },
];
