import {GetAlarmsUrl, GetDataUrl, GetExportInfoUrl} from "../constants";
import {GetDataRequest, GetExportInfoRequest} from "../requests/timeSeries";
import {methodGet, verticalFetch} from "./httpRequests";

export const getData = (req: GetDataRequest) => {
    return verticalFetch(methodGet, GetDataUrl(req))
}

export const getAlarms = () => {
    return verticalFetch(methodGet, GetAlarmsUrl)
}

export const getExportInfo = (req: GetExportInfoRequest) => {
    return verticalFetch(methodGet, GetExportInfoUrl(req))
}