import { MenuType, SubMenuType } from "../types/navbar/types";
import {
  LineChartOutlined,
  HistoryOutlined,
  LockOutlined,
  FileSearchOutlined,
} from "@ant-design/icons";
import {
  createCustomerPageUrl,
  createInstallationUrl,
  createSubscriptionPageUrl,
  customersListPageUrl,
  installationsListToUpdateUrl,
  subscriptionsListToDataHistoryPageUrl,
  subscriptionsListToInstallationPageUrl,
  subscriptionsListToReportsPageUrl,
  subscriptionsListToUpdateUrl,
} from "../pages/costants";

const adminItem: (t: any) => SubMenuType[] = (t: any) => [
  {
    key: "create_customer",
    label: t("createUser"),
    link: createCustomerPageUrl,
    elements: [],
  },
  {
    key: "update_customer",
    label: t("updateUser"),
    link: customersListPageUrl,
    elements: [],
  },
  {
    key: "create_subscription",
    label: t("createSubscription"),
    link: createSubscriptionPageUrl,
    elements: [],
  },
  {
    key: "update_subscription",
    label: t("updateSubscription"),
    link: subscriptionsListToUpdateUrl,
    elements: [],
  },
  {
    key: "create_installer",
    label: t("createInstallation"),
    link: createInstallationUrl,
    elements: [],
  },
  {
    key: "update_installer",
    label: t("updateInstallation"),
    link: installationsListToUpdateUrl,
    elements: [],
  },
];

export const adminMenuItems: (t: any) => MenuType[] = (t: any) => [
  {
    key: "customer",
    label: t("dataAnalysis"),
    icon: <LineChartOutlined />,
    elements: [],
    link: subscriptionsListToInstallationPageUrl,
  },
  {
    key: "history",
    label: t("dataHistory"),
    icon: <HistoryOutlined />,
    elements: [],
    link: subscriptionsListToDataHistoryPageUrl,
  },
  {
    key: "reports",
    label: t("reports"),
    icon: <FileSearchOutlined />,
    elements: [],
    link: subscriptionsListToReportsPageUrl,
  },
  {
    key: "admin",
    label: t("adminPanel"),
    icon: <LockOutlined />,
    elements: adminItem(t),
  },
];
