import React from "react";
import MUIDataTable, { Responsive } from "mui-datatables";
import "./Table.css";

import { ResponsiveTableColumnType } from "./tableWithGetDataFunction";
import { rowsPerTablePage } from "../utilities/utilities";

interface TableWithoutGetDataFunctionProps {
  columns: ResponsiveTableColumnType[];
  data: Object[];
  icon?: boolean;
  totalDataLength: number;
  changeSort: any;
  changePage: any;
  changeSearch: any;
}

const TableWithoutGetDataFunction: React.FC<
  TableWithoutGetDataFunctionProps
> = ({
  columns,
  data,
  icon,
  totalDataLength,
  changePage,
  changeSort,
  changeSearch,
}) => {
  const hideIconsOptions = {
    selectableRows: "none" as "none",
    isRowSelectable: () => false,
    download: false,
    filter: false,
    print: true,
    search: false,
    viewColumns: false,
    responsive: "standard" as Responsive,
  };

  let options: Object = {
    selectableRows: "none" as "none",
    filter: false,
    print: false,
    download: false,
    serverSide: true,
    responsive: "standard" as Responsive,
    isRowSelectable: () => false,
    expandableRows: false,
    renderExpandableRow: undefined,
    rowsPerPage: rowsPerTablePage,
    count: totalDataLength,
    rowsPerPageOptions: [],
    onTableChange: (action: string, tableState: any) => {
      switch (action) {
        case "changePage":
          changePage(tableState.page);
          break;
        case "sort":
          let sort;
          if (tableState.sortOrder.direction === "asc") {
            sort = tableState.sortOrder.name;
          } else {
            sort = `-${tableState.sortOrder.name}`;
          }
          changeSort(sort);
          break;
        case "search":
          changeSearch(tableState.searchText);
          break;
        default:
          break;
      }
    },
  };

  if (icon === false) {
    return (
      <div className="verticalBorder">
        <MUIDataTable
          title={""}
          data={data}
          columns={columns}
          options={hideIconsOptions}
        />
      </div>
    );
  } else {
    return (
      <div className="verticalBorder">
        <MUIDataTable
          title={""}
          data={data}
          columns={columns}
          options={options}
        />
      </div>
    );
  }
};

export default TableWithoutGetDataFunction;
