import "./charts/lineChart/LineChart.css";
import "../../../App.css";
import { DataChart } from "../../../api/requests/timeSeries";
import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import { Aggregation } from "../../../actions/lineChart";
import TitleWithBack from "../../../containers/TitleWithBack";
import InstallationSummary from "../../../containers/pages/installationPage/summaryCards/InstallationSummary";
import RealTimeSummary from "../../../containers/pages/installationPage/summaryCards/RealTimeSummary";
import { Installation } from "../../../api/requests/installationsService";
import { getInstallation } from "../../../api/services/installationsService";
import { heliosCorrosionModel, heliosMagmaModel } from "./costant";
import ChartSession from "./ChartSession";
import SessionComparator from "./SessionComparator";
import logo from "../../../logo/Logo_LETOMEC_Orizzontale_1.png";
import { useTranslation } from "react-i18next";

type TimeSeriesProps = {
  setDataStartDate: (
    dev: string,
    a: Aggregation[],
    startDate: Date,
    period: string,
    interval: number,
    variable: any
  ) => void;
  setPeriod: (
    dev: string,
    a: Aggregation[],
    startDate: Date,
    period: string,
    interval: number,
    variable: any
  ) => void;
  startUpdateChartData: (
    dev: string,
    a: Aggregation[],
    period: string,
    interval: number,
    variable: any
  ) => void;
  clearDataInterval: () => void;
  data_chart: {
    data_chart: { [s: string]: DataChart };
  };
  installation_id: string;
};
const InstallationPage: React.FC<TimeSeriesProps> = ({
  setDataStartDate,
  setPeriod,
  startUpdateChartData,
  clearDataInterval,
  data_chart,
  installation_id,
}) => {
  const { t } = useTranslation();

  const [selectedInstallation, setSelectedInstallation] =
    useState<Installation | null>(null);

  useEffect(() => {
    getInstallation({ id: installation_id }).then((res) => {
      if (res && res.installation) {
        setSelectedInstallation(res.installation);
      }
    });
  }, []);

  if (selectedInstallation === null) {
    return null;
  }

  switch (selectedInstallation.model) {
    case heliosMagmaModel:
    case heliosCorrosionModel:
      return (
        <MagmaCorrosionRes
          selectedInstallation={selectedInstallation}
          setDataStartDate={setDataStartDate}
          setPeriod={setPeriod}
          startUpdateChartData={startUpdateChartData}
          clearDataInterval={clearDataInterval}
          data_chart={data_chart}
        />
      );
    default:
      return (
        <>
          <TitleWithBack title={t("dataAnalysis")} key="real_time_data" />
          <div id="divToPrint">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img src={logo} className="image" style={{ width: "40%" }} />
            </div>
            <SessionComparator installation={selectedInstallation} />
          </div>
        </>
      );
  }
};
export default InstallationPage;

type HeliosCorrosionProps = {
  selectedInstallation: Installation;
  setDataStartDate: (
    dev: string,
    a: Aggregation[],
    startDate: Date,
    period: string,
    interval: number,
    variable: any
  ) => void;
  setPeriod: (
    dev: string,
    a: Aggregation[],
    startDate: Date,
    period: string,
    interval: number,
    variable: any
  ) => void;
  startUpdateChartData: (
    dev: string,
    a: Aggregation[],
    period: string,
    interval: number,
    variable: any
  ) => void;
  clearDataInterval: () => void;
  data_chart: {
    data_chart: { [s: string]: DataChart };
  };
};

const MagmaCorrosionRes: React.FC<HeliosCorrosionProps> = ({
  selectedInstallation,
  setDataStartDate,
  setPeriod,
  startUpdateChartData,
  clearDataInterval,
  data_chart,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <TitleWithBack title={t("dataAnalysis")} key="real_time_data" />
      <div id="divToPrint">
        <Row className="cards">
          <Col xs={24} xl={10} className="card-responsive">
            <InstallationSummary selected_installation={selectedInstallation} />
          </Col>
          <Col xs={24} xl={10} className="card-responsive">
            <RealTimeSummary
              device_id={selectedInstallation.id}
              model={selectedInstallation.model}
            />
          </Col>
        </Row>
        <ChartSession
          setDataStartDate={setDataStartDate}
          setPeriod={setPeriod}
          startUpdateChartData={startUpdateChartData}
          clearDataInterval={clearDataInterval}
          data_chart={data_chart.data_chart}
          installation_id={selectedInstallation.id}
        />
      </div>
    </>
  );
};
