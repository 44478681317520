import { PoweroffOutlined, UserOutlined } from "@ant-design/icons";
import "./Header.css";
import { logout } from "../../api/services/loginService";
import { useHistory } from "react-router";
import React from "react";
import { routes } from "../pages/costants";
import {
  AppBar,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Divider,
} from "@material-ui/core";
import clsx from "clsx";
import MenuIcon from "@material-ui/icons/Menu";
import { useStyles } from "../../constants/muiStyles";
import { useTranslation } from "react-i18next";

type HeaderItemProps = {
  SetLogged: (b: boolean) => void;
  openNavbar: () => void;
};

const HeaderItem: React.FC<HeaderItemProps> = ({ SetLogged, openNavbar }) => {
  const classes = useStyles();
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const logOut = () => {
    logout().then((res) => {
      if (res && res.status === "success") {
        history.push(res.location);
        SetLogged(false);
      }
    });
  };

  const openMenu = (event: React.MouseEvent<HTMLDivElement>) =>
    setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  return (
    <AppBar position="fixed" className={clsx(classes.appBar)}>
      <Toolbar className="header">
        <IconButton onClick={openNavbar}>
          <MenuIcon />
        </IconButton>

        <Avatar onClick={openMenu} src="/broken-image.jpg" />
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={closeMenu}
        >
          <MenuItem
            onClick={() => {
              i18n.language === "en"
                ? i18n.changeLanguage("it")
                : i18n.changeLanguage("en");
            }}
          >
            {i18n.language === "en" ? "🇮🇹 Italiano" : "🇬🇧 English"}
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              history.push(routes.profile);
              closeMenu();
            }}
          >
            <UserOutlined /> {t("profile")}
          </MenuItem>
          <MenuItem
            onClick={() => {
              closeMenu();
              logOut();
            }}
          >
            <PoweroffOutlined /> {t("logout")}
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default HeaderItem;
