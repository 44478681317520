import { InputType } from "../../types/form/types";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

export const inputs: (t: any) => InputType[] = (t: any) => [
  {
    key: "email",
    name: "email",
    label: t("email"),
    icon: <UserOutlined className="site-form-item-icon" />,
  },
  {
    key: "password",
    name: "password",
    label: t("password"),
    type: "password",
    icon: <LockOutlined className="site-form-item-icon" />,
  },
];
