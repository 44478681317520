import {
  TableWithGetDataFunctionCell,
  ResponsiveTableColumnType,
} from "../../tableWithGetDataFunction";
import "../../../App.css";
import {
  dateTimeType,
  linkType,
  stringType,
} from "../../../utilities/utilities";

export const customersListColumns: (
  linkFunction: (value: string) => void,
  t: any
) => ResponsiveTableColumnType[] = (linkFunction, t) => [
  {
    label: t("name"),
    name: "name",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => {
        return (
          <TableWithGetDataFunctionCell
            type={linkType}
            value={value}
            link={() => {
              linkFunction(data.tableData[data.rowIndex].uid);
            }}
          />
        );
      },
    },
  },
  {
    label: t("email"),
    name: "email",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <TableWithGetDataFunctionCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("phone"),
    name: "user_info",
    options: {
      sort: false,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <div className="cell">
          {data.tableData[data.rowIndex].user_info?.phone}
        </div>
      ),
    },
  },
  {
    label: t("address"),
    name: "user_info",
    options: {
      sort: false,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <div className="cell">
          {data.tableData[data.rowIndex].user_info?.address}
        </div>
      ),
    },
  },
  {
    label: t("city"),
    name: "user_info",
    options: {
      sort: false,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <div className="cell">
          {data.tableData[data.rowIndex].user_info?.city}
        </div>
      ),
    },
  },
  {
    label: t("province"),
    name: "user_info",
    options: {
      sort: false,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <div className="cell">
          {data.tableData[data.rowIndex].user_info?.province}
        </div>
      ),
    },
  },
  {
    label: t("country"),
    name: "user_info",
    options: {
      sort: false,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <div className="cell">
          {data.tableData[data.rowIndex].user_info?.country}
        </div>
      ),
    },
  },
  {
    label: t("zipCode"),
    name: "user_info",
    options: {
      sort: false,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <div className="cell">
          {data.tableData[data.rowIndex].user_info?.zip_code}
        </div>
      ),
    },
  },
  {
    label: t("creationDate"),
    name: "created_at",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <TableWithGetDataFunctionCell type={dateTimeType} value={value} />
      ),
    },
  },
];
