import React, { useCallback } from "react";
import { DownloadReportInstallationUrl } from "../../../api/constants";
import { GetAllInstallationReports } from "../../../api/services/reportsService";
import TitleWithBack from "../../../containers/TitleWithBack";
import TableWithGetDataFunction from "../../tableWithGetDataFunction";
import "./ReportsInstallation.css";
import { reportsListColumns } from "./tableColumn";
import { useTranslation } from "react-i18next";

type ReportsInstallationProps = {
  installationId: string;
};

const ReportsInstallation: React.FC<ReportsInstallationProps> = ({
  installationId,
}) => {
  const { t } = useTranslation();

  const linkFunction = useCallback(
    (report_id: string, installation_id: string) => {
      console.log(report_id);
      console.log(installation_id);
      // Create a link element
      const link = document.createElement("a");

      // Set link's href to point to the Blob URL
      link.href = DownloadReportInstallationUrl({
        report_id: report_id,
        installation_id: installation_id,
      });
      //link.download = "download.csv";

      // Append link to the body
      document.body.appendChild(link);

      // Dispatch click event on the link
      // This is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );

      // Remove link from body
      document.body.removeChild(link);
    },
    []
  );

  return (
    <>
      <TitleWithBack title={t("reports")} key="report_title" />
      <TableWithGetDataFunction
        columns={reportsListColumns(linkFunction, t)}
        getPageAndSortedData={GetAllInstallationReports}
        defaultSort="-ts"
        id={installationId}
        responseDataName="reports"
      />
    </>
  );
};

export default ReportsInstallation;
