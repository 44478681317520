import "./UpdateSubscription.css";
import "../../../App.css";
import React, { useEffect, useState } from "react";
import { GetUser } from "../../../api/services/userService";
import { GetUserRequest, User } from "../../../api/requests/userService";
import { Descriptions } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";

type CustomerSummaryProps = {
  customer_id: string;
};

const CustomerSummary: React.FC<CustomerSummaryProps> = ({ customer_id }) => {
  const { t } = useTranslation();

  const [customer, setCustomer] = useState<User | null>(null);
  useEffect(() => {
    GetUser({ user_id: customer_id } as GetUserRequest).then((res) => {
      if (res && res.user) {
        setCustomer(res.user);
      }
    });
  }, []);

  const dateFormatter = (date: string) => moment(date).format("DD/MM/YY HH:mm");

  return (
    <Descriptions
      labelStyle={{ fontWeight: "bold" }}
      style={{ marginTop: "2%", marginBottom: "2%" }}
      title={t("user")}
      bordered
    >
      {customer && customer.name ? (
        <Descriptions.Item label={t("name")} span={3}>
          {customer.name}
        </Descriptions.Item>
      ) : null}
      {customer && customer.email ? (
        <Descriptions.Item label={t("email")} span={3}>
          {customer.email}
        </Descriptions.Item>
      ) : null}
      {customer && customer.created_at ? (
        <Descriptions.Item label={t("creationDate")} span={3}>
          {dateFormatter(customer.created_at)}
        </Descriptions.Item>
      ) : null}
    </Descriptions>
  );
};

export default CustomerSummary;
