import React from "react";
import { Descriptions } from "antd";
import { Subscription } from "../../api/requests/subscription";
import "./summary.css";
import { useTranslation } from "react-i18next";

type SubscriptionSummaryProps = {
  subscription: Subscription;
};

const SubscriptionSummary: React.FC<SubscriptionSummaryProps> = ({
  subscription,
}) => {
  const { t } = useTranslation();

  return (
    <div className="description">
      <Descriptions
        labelStyle={{ fontWeight: "bold" }}
        title={t("company")}
        bordered={true}
        size={"small"}
      >
        <Descriptions.Item label={t("name")}>
          {subscription.company_info.name}
        </Descriptions.Item>
        <Descriptions.Item label={t("sdi")}>
          {subscription.company_info.sdi}
        </Descriptions.Item>
        <Descriptions.Item label={t("pec")}>
          {subscription.company_info.pec}
        </Descriptions.Item>
        <Descriptions.Item label={t("vat")}>
          {subscription.company_info.vat_number}
        </Descriptions.Item>
        <Descriptions.Item label={t("phone")}>
          {subscription.company_info.phone}
        </Descriptions.Item>
        <Descriptions.Item label={t("address")}>
          {subscription.company_info.address}, {subscription.company_info.city}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default SubscriptionSummary;
