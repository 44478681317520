import "./charts/lineChart/LineChart.css";
import "../../../App.css";
import React, { useCallback, useState } from "react";
import { Form, message } from "antd";
import { Installation } from "../../../api/requests/installationsService";
import { SendInformation } from "../../../api/services/reportsService";
import {
  SendInformationRequest,
  SessionInfoType,
} from "../../../api/requests/reportsService";
import TestComparerResultTable from "./TableComparator";
import { Filters } from "../../types/table/types";
import FormComparator from "./FormComparator";
import {
  helios2GalvModel,
  helios2Model,
  helios3Model,
  helios4HotProbeModel,
} from "./costant";
import { useTranslation } from "react-i18next";

type ChartSessionProps = {
  installation: Installation;
};
const SessionComparator: React.FC<ChartSessionProps> = ({ installation }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [rows, setRows] = useState<SessionInfoType[] | null>(null);
  const [filters, setFilters] = useState<Filters[] | null>(null);

  const resetSearch = useCallback(() => {
    setRows(null);
  }, []);

  const setFilter = (tmp: string[], title: string, tmp_filters: Filters[]) => {
    let text: string = "";
    tmp.map((el, index) => {
      if (title === t("sampleType")) {
        el = checkSampleType(el);
      }
      if (index !== tmp.length - 1) {
        text = `${text}${el} - `;
      } else {
        text = `${text}${el}`;
      }
    });
    tmp_filters.push({
      key: title,
      value: text,
    });
    return tmp_filters;
  };

  const checkType = (type: string) => {
    if (
      installation.model === helios3Model ||
      installation.model === helios4HotProbeModel
    ) {
      let result: string = "";
      switch (type) {
        case "Constant temperature":
          result = "tconst";
          break;
        case "Temperature programmed desorption":
          result = "tramp";
          break;
        case "Vergelle":
          result = "vergelle";
          break;
      }
      return result;
    } else {
      return type;
    }
  };

  const checkSampleType = (x: string) => {
    switch (x) {
      case "usibor 1500alsi hot stamped - as 80":
        return "USIBOR 1500 AlSi AS80";
      case "usibor 1500alsi hot stamped - as 150":
        return "USIBOR 1500 AlSi AS150";
    }
    return x;
  };

  const handleSubmit = () => {
    return form.validateFields().then((values) => {
      let request: SendInformationRequest = {
        installation_id: installation.id,
        type: checkType(values.testType),
        start: values.date[0].format("YYYY-MM-DD"),
        end: values.date[1].format("YYYY-MM-DD"),
      };
      if (
        installation.model === helios2Model ||
        installation.model === helios2GalvModel
      ) {
        if (values.material && values.material.lenght > 0) {
          request.material = values.material;
        }
        if (values.specimenSupplier && values.specimenSupplier.lenght > 0) {
          request.supplier = values.specimenSupplier;
        }
        if (values.specimenThickness && values.specimenThickness.lenght > 0) {
          request.thickness = values.specimenThickness;
        }
      }
      if (installation.model === helios2GalvModel) {
        if (values.bathSupplier && values.bathSupplier.lenght > 0) {
          request.bath_supplier = values.bathSupplier;
        }
        if (values.bathType && values.bathType.lenght > 0) {
          request.bath_type = values.bathType;
        }
        if (values.bathPh && values.bathPh.lenght > 0) {
          request.bath_ph = values.bathPh;
        }
        if (values.edc && values.edc.lenght > 0) {
          request.edc = values.edc;
        }
        if (values.electronicVoltage && values.electronicVoltage.lenght > 0) {
          request.electronic_voltage = values.electronicVoltage;
        }
        if (values.bup && values.bup.lenght > 0) {
          request.bup = values.bup;
        }
      }
      if (installation.model === helios3Model) {
        if (values.sampleType && values.sampleType.lenght > 0) {
          request.sample_type = values.sampleType;
        }
        if (values.sampleSupplier && values.sampleSupplier.lenght > 0) {
          request.sample_supplier = values.sampleSupplier;
        }
      }
      if (installation.model === helios4HotProbeModel) {
        if (values.sampleType && values.sampleType.lenght > 0) {
          values.sampleType.map((el: string) => {
            if (String(el).includes("usibor")) {
              let tmp: string[] = String(el).split(" - ");
              request.specimen_material = (
                request.specimen_material || []
              ).concat([tmp[0]]);
              request.coating_thickness = (
                request.coating_thickness || []
              ).concat([tmp[1]]);
            }
          });
        }
        if (values.supplier && values.supplier.lenght > 0) {
          request.supplier = values.supplier;
        }
        if (
          values.specimenMaterial &&
          values.specimenMaterial.lenght > 0 &&
          !request.specimen_material
        ) {
          request.specimen_material = values.specimenMaterial;
        }
        if (values.specimenThickness && values.specimenThickness.lenght > 0) {
          request.steel_thickness = values.specimenThickness;
        }
        if (values.coatingThickness && values.coatingThickness.lenght > 0) {
          request.coating_thickness = values.coatingThickness;
        }
      }
      SendInformation(request).then((res) => {
        if (res && !res.err) {
          let tmp_filters: Filters[] = [
            {
              key: t("testType"),
              value: values.testType,
            },
            {
              key: t("startDate"),
              value: values.date[0].format("YYYY-MM-DD"),
            },
            {
              key: t("endDate"),
              value: values.date[1].format("YYYY-MM-DD"),
            },
          ];
          //Helios 2 && Helios 2 Galv
          if (values.material) {
            tmp_filters = setFilter(
              values.material,
              t("material"),
              tmp_filters
            );
          }
          if (values.specimenSupplier) {
            tmp_filters = setFilter(
              values.specimenSupplier,
              t("specimenSupplier"),
              tmp_filters
            );
          }
          if (values.specimenThickness) {
            tmp_filters = setFilter(
              values.specimenThickness,
              t("specimenThickness"),
              tmp_filters
            );
          }
          //Helios 2 Galv
          if (values.bathSupplier) {
            tmp_filters = setFilter(
              values.bathSupplier,
              t("bathSupplier"),
              tmp_filters
            );
          }
          if (values.bathType) {
            tmp_filters = setFilter(
              values.bathType,
              t("bathType"),
              tmp_filters
            );
          }
          if (values.bathPh) {
            tmp_filters = setFilter(values.bathPh, t("bathPh"), tmp_filters);
          }
          if (values.edc) {
            tmp_filters = setFilter(
              values.edc,
              `${t("electroliticDensityCurrent")} (mA/cm 2 )`,
              tmp_filters
            );
          }
          //Helios 3 && Helios 4 Hot Probe
          if (values.sampleType) {
            tmp_filters = setFilter(
              [...(values.sampleType || [])],
              t("sampleType"),
              tmp_filters
            );
          }
          if (values.sampleSupplier) {
            tmp_filters = setFilter(
              values.sampleSupplier,
              t("sampleSupplier"),
              tmp_filters
            );
          }
          //Helios 4 Hot Probe
          if (values.specimenMaterial) {
            tmp_filters = setFilter(
              values.specimenMaterial,
              t("specimenMaterial"),
              tmp_filters
            );
          }
          if (values.coatingThickness) {
            tmp_filters = setFilter(
              values.coatingThickness,
              t("coatingThickness"),
              tmp_filters
            );
          }
          setFilters(tmp_filters);
          setRows(res.testsessions_info);
          if (!res.testsessions_info) {
            message.info(t("noResultsForFiltersEntered"));
          }
        }
      });
    });
  };

  if (!rows) {
    return (
      <>
        <FormComparator
          form={form}
          handleSubmit={handleSubmit}
          model={installation.model}
          installation={installation}
        />
      </>
    );
  } else {
    return (
      <TestComparerResultTable
        installation={installation}
        filters={filters || []}
        rows={rows}
        resetSearch={resetSearch}
      />
    );
  }
};
export default SessionComparator;
