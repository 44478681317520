import { InputType } from "../../types/form/types";

export const installationFields: (t: any) => InputType[] = (t: any) => [
  {
    key: "name",
    name: "name",
    label: t("name"),
    rules: [{ required: true, message: "Inserire nome!" }],
  },
  {
    key: "description",
    name: "description",
    label: t("description"),
  },
];

export const installationModels: string[] = [
  "Helios 2",
  "Helios 2 Galv",
  "Helios 3",
  "Helios 4 Hot Probe",
  "Helios 4 Corrosion",
  "Helios Magma",
];
