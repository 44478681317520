import { CloudDownloadOutlined } from "@material-ui/icons";
import React from "react";
import { iconType, stringType } from "../../../utilities/utilities";
import {
  TableWithGetDataFunctionCell,
  ResponsiveTableColumnType,
} from "../../tableWithGetDataFunction";

export const reportsListColumns: (
  linkFunction: (id: string, path: string) => void,
  t: any
) => ResponsiveTableColumnType[] = (linkFunction, t) => [
  {
    label: t("sessionId"),
    name: "session_id",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <TableWithGetDataFunctionCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("sessionName"),
    name: "session_name",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <TableWithGetDataFunctionCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("creationDate"),
    name: "ts",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <TableWithGetDataFunctionCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("format"),
    name: "format",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <TableWithGetDataFunctionCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("type"),
    name: "type",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <TableWithGetDataFunctionCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("download"),
    name: "",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <TableWithGetDataFunctionCell
          type={iconType}
          link={() =>
            linkFunction(
              data.tableData[data.rowIndex].report_id,
              data.tableData[data.rowIndex].installation_id
            )
          }
          value={""}
          icon={<CloudDownloadOutlined />}
        />
      ),
    },
  },
];
