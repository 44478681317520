/*
export const UpdateInstallation = (r: UpdateInstallationRequest) => {
    return verticalFetch(methodPut, `${InstallationsServiceUrl}/${r.id}`, JSON.stringify(r))
}
*/

import { BackendUrl, ReportsServiceUrl } from "../constants";
import { SendInformationRequest } from "../requests/reportsService";
import { methodGet, methodPost, verticalFetch } from "./httpRequests";
import { rowsPerTablePage } from "../../utilities/utilities";

//Get all reports of an installation
export const GetAllInstallationReports = (
  id: string,
  page: string,
  sort: string,
  search: string
) => {
  return verticalFetch(
    methodGet,
    `${ReportsServiceUrl}/${id}?page=${page}&rows=${rowsPerTablePage}&sort=${sort}&search=${search}`
  );
};

export const SendInformation = (req: SendInformationRequest) => {
  let url = `${BackendUrl}/reports/testsession/info/${req.installation_id}/type/${req.type}?start=${req.start}&end=${req.end}`;
  //Helios 2 && Helios 2 Galv
  if (req.material) {
    req.material.map((el: string) => {
      url = url.concat(`&material=${el}`);
    });
  }
  if (req.supplier) {
    req.supplier.map((el: string) => {
      url = url.concat(`&supplier=${el}`);
    });
  }
  if (req.thickness) {
    req.thickness.map((el: string) => {
      url = url.concat(`&thickness=${el}`);
    });
  }
  //Helios 2 Galv
  if (req.bath_supplier) {
    req.bath_supplier.map((el: string) => {
      url = url.concat(`&bath_supplier=${el}`);
    });
  }
  if (req.bath_type) {
    req.bath_type.map((el: string) => {
      url = url.concat(`&bath_type=${el}`);
    });
  }
  if (req.bath_ph) {
    req.bath_ph.map((el: string) => {
      url = url.concat(`&bath_ph=${el}`);
    });
  }
  if (req.edc) {
    req.edc.map((el: string) => {
      url = url.concat(`&edc=${el}`);
    });
  }
  if (req.electronic_voltage) {
    req.electronic_voltage.map((el: string) => {
      url = url.concat(`&electronic_voltage=${el}`);
    });
  }
  if (req.bup) {
    req.bup.map((el: string) => {
      url = url.concat(`&bup=${el}`);
    });
  }
  //Helios 3
  if (req.sample_type) {
    req.sample_type.map((el: string) => {
      url = url.concat(`&sample_type=${el}`);
    });
  }
  if (req.sample_supplier) {
    req.sample_supplier.map((el: string) => {
      url = url.concat(`&sample_supplier=${el}`);
    });
  }
  //Helios 4 HotProbe
  if (req.steel_thickness) {
    req.steel_thickness.map((el: string) => {
      url = url.concat(`&steel_thickness=${el}`);
    });
  }
  if (req.specimen_material) {
    req.specimen_material.map((el: string) => {
      url = url.concat(`&specimen_material=${el}`);
    });
  }
  if (req.coating_thickness) {
    req.coating_thickness.map((el: string) => {
      url = url.concat(`&coating_thickness=${el}`);
    });
  }
  return verticalFetch(methodGet, url);
};

//Get test data of an installation
export const GetTestData = (
  installation_id: string,
  value_session_id: string[]
) => {
  let url = `${BackendUrl}/reports/testsession/data/${installation_id}/type/test_data`;
  let body = { session_id: value_session_id };
  return verticalFetch(methodPost, url, JSON.stringify(body));
};

//Get test result of an installation
export const GetTestResult = (
  installation_id: string,
  value_session_id: string[]
) => {
  let url = `${BackendUrl}/reports/testsession/data/${installation_id}/type/test_result`;
  let body = { session_id: value_session_id };
  return verticalFetch(methodPost, url, JSON.stringify(body));
};
