import React from "react";
import { Divider } from "antd";
import Title from "antd/es/typography/Title";

import "../App.css";

type CustomTitleProps = {
  title: string;
};

const CustomTitle: React.FC<CustomTitleProps> = ({ title }) => {
  return (
    <>
      <Title className="tableTitle" level={2}>
        {title}
      </Title>
      <Divider />
    </>
  );
};

export default CustomTitle;
