import { localeDateFormatter, stringType } from "../../../utilities/utilities";
import { TableWithGetDataFunctionCell } from "../../tableWithGetDataFunction";
import { Checkbox } from "antd";

// MODELS
export const helios2Model = "helios_2";
export const helios2GalvModel = "helios_2_galv";
export const helios3Model = "helios_3";
export const helios4HotProbeModel = "helios_4";
export const heliosCorrosionModel = "helios_4_corrosion";
export const heliosMagmaModel = "helios_magma";

/*
-----------------------------------------CHART TYPE-----------------------------------------
*/

export type machineVariable = {
  key: string;
  label: string;
};

export const heliosMagma: machineVariable[] = [
  { label: "Air temperature in °C", key: "t" },
  { label: "Thermocouple (heater) temperature in °C", key: "tk" },
  { label: "PID state", key: "tt" },
  { label: "Air flow", key: "f" },
  { label: "Target flow", key: "tf" },
  { label: "Security relay status", key: "a" },
  { label: "Solenoid valve status", key: "v" },
  { label: "Hydrogen signal in mV", key: "h" },
  { label: "S2 sensor signal (mV)", key: "s2" },
  { label: "S3 sensor signal (mV)", key: "s3" },
  { label: "S4 sensor signal (mV)", key: "s4" },
  { label: "S5 sensor signal (mV)", key: "s5" },
  { label: "S6 sensor signal (mV)", key: "s6" },
  { label: "S7 sensor signal (mV)", key: "s7" },
  { label: "S8 sensor signal (mV)", key: "s8" },
];

export const helios3: machineVariable[] = [
  { label: "Hydrogen sensor temp", key: "t" },
  { label: "Furnace temperature", key: "tj" },
  { label: "Hydrogen signal", key: "h" },
  { label: "Flow", key: "f" },
  { label: "Alarm relay", key: "a" },
  { label: "Target temperature", key: "tt" },
  { label: "Final temperature", key: "ft" },
  { label: "Slope temperature", key: "st" },
];

export const helios4: machineVariable[] = [
  { label: "Hydrogen sensor temp", key: "t" },
  { label: "Hydrogen signal", key: "h" },
  { label: "Target temperature", key: "p" },
  { label: "Final temperature", key: "ft" },
  { label: "Slope temperature", key: "st" },
];

export const helios2: machineVariable[] = [
  { label: "Specimen presence", key: "sp" },
  { label: "Alarm relay", key: "a" },
  { label: "Solution temperature", key: "t" },
  { label: "Hydrogen sensor signal", key: "h" },
  { label: "Cell emptiness status", key: "c" },
  { label: "Generator current", key: "i" },
  { label: "Generator voltage", key: "v" },
  { label: "Input pump status", key: "in" },
  { label: "Output pump status", key: "out" },
  { label: "Generator status", key: "g" },
];

//FormComparator

export const helios2TestType = [
  { UNI11734: "UNI11734" },
  { ENAMEL: "ENAMEL" },
  { "EN10209:2014": "EN10209:2014" },
  { FAST: "FAST" },
];

export const helios3TestType = [
  { "Constant temperature": "Constant temperature" },
  { "Temperature programmed desorption": "Temperature programmed desorption" },
  { Vergelle: "Vergelle" },
];

export const helios4HotProbeSampleType = [
  { "usibor 1500alsi hot stamped - as 80": "USIBOR 1500 AlSi AS80" },
  { "usibor 1500alsi hot stamped - as 150": "USIBOR 1500 AlSi AS150" },
  { Other: "Other" },
];

export const helios2Material = [
  { ColdRolledDCEK: "Cold Rolled DCEK" },
  { coldrolleddcedopen: "Cold Rolled DCED Open Coil steel" },
  { ColdRolledDCEDIF: "Cold Rolled DCED IF steel" },
  { HotRolledHSLA: "Hot Rolled HSLA" },
  { HotRolledCarbonSteel: "Hot Rolled Carbon Steel" },
];

//TableComparator
export const helios2Table = (countLines: any[], setCountLines: any, t: any) => {
  return [
    {
      label: t("selected"),
      name: "session_id",
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: string, data: any) => (
          <div className="cell">
            <Checkbox
              checked={countLines.includes(data.rowData[0])}
              key={data.rowData[0]}
              onChange={(event) => {
                if (event.target.checked !== undefined) {
                  if (event.target.checked) {
                    let tmp = countLines;
                    setCountLines(tmp.concat([data.rowData[0]]));
                  } else {
                    if (countLines.length > 1) {
                      let tmp = countLines;
                      let i = tmp.indexOf(data.rowData[0]);
                      if (i !== -1) {
                        tmp = tmp.filter(function (item) {
                          return item !== tmp[i];
                        });
                      }
                      setCountLines(tmp);
                    } else {
                      setCountLines([]);
                    }
                  }
                }
              }}
            />
          </div>
        ),
      },
    },
    {
      label: t("sessionName"),
      name: "session_name",
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: string, data: any) => (
          <TableWithGetDataFunctionCell type={stringType} value={value} />
        ),
      },
    },
    {
      label: t("date"),
      name: "test_date",
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: string, data: any) => (
          <TableWithGetDataFunctionCell
            type={stringType}
            value={localeDateFormatter(value)}
          />
        ),
      },
    },
    {
      label: t("testType"),
      name: "test_type",
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: string, data: any) => (
          <TableWithGetDataFunctionCell type={stringType} value={value} />
        ),
      },
    },
    {
      label: t("materials"),
      name: "material",
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: string, data: any) => (
          <TableWithGetDataFunctionCell type={stringType} value={value} />
        ),
      },
    },
    {
      label: t("specimenSupplier"),
      name: "supplier",
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: string, data: any) => (
          <TableWithGetDataFunctionCell type={stringType} value={value} />
        ),
      },
    },
    {
      label: t("specimenThickness"),
      name: "thickness",
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: string, data: any) => (
          <TableWithGetDataFunctionCell type={stringType} value={value} />
        ),
      },
    },
    {
      label: "D63% (cm²/sec)",
      name: "test_result",
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: string, data: any) => (
          <TableWithGetDataFunctionCell type={stringType} value={value} />
        ),
      },
    },
  ];
};

export const helios2GalvTable = (
  countLines: any[],
  setCountLines: any,
  t: any
) => {
  return [
    {
      label: t("selected"),
      name: "session_id",
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: string, data: any) => (
          <div className="cell">
            <Checkbox
              checked={countLines.includes(data.rowData[0])}
              key={data.rowData[0]}
              onChange={(event) => {
                if (event.target.checked !== undefined) {
                  if (event.target.checked) {
                    let tmp = countLines;
                    setCountLines(tmp.concat([data.rowData[0]]));
                  } else {
                    if (countLines.length > 1) {
                      let tmp = countLines;
                      let i = tmp.indexOf(data.rowData[0]);
                      if (i !== -1) {
                        tmp = tmp.filter(function (item) {
                          return item !== tmp[i];
                        });
                      }
                      setCountLines(tmp);
                    } else {
                      setCountLines([]);
                    }
                  }
                }
              }}
            />
          </div>
        ),
      },
    },
    {
      label: t("date"),
      name: "test_date",
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: string, data: any) => (
          <TableWithGetDataFunctionCell
            type={stringType}
            value={localeDateFormatter(value)}
          />
        ),
      },
    },
    {
      label: t("materials"),
      name: "material",
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: string, data: any) => (
          <TableWithGetDataFunctionCell type={stringType} value={value} />
        ),
      },
    },
    {
      label: t("specimenSupplier"),
      name: "supplier",
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: string, data: any) => (
          <TableWithGetDataFunctionCell type={stringType} value={value} />
        ),
      },
    },
    {
      label: t("specimenThickness"),
      name: "thickness",
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: string, data: any) => (
          <TableWithGetDataFunctionCell type={stringType} value={value} />
        ),
      },
    },
    {
      label: t("bathSupplier"),
      name: "bath_supplier",
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: string, data: any) => (
          <TableWithGetDataFunctionCell type={stringType} value={value} />
        ),
      },
    },
    {
      label: t("bathType"),
      name: "bath_type",
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: string, data: any) => (
          <TableWithGetDataFunctionCell type={stringType} value={value} />
        ),
      },
    },
    {
      label: t("bathPh"),
      name: "bath_ph",
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: string, data: any) => (
          <TableWithGetDataFunctionCell type={stringType} value={value} />
        ),
      },
    },
    {
      label: t("electroliticDensityCurrent"),
      name: "edc",
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: string, data: any) => (
          <TableWithGetDataFunctionCell type={stringType} value={value} />
        ),
      },
    },
    {
      label: t("electroliticVoltage"),
      name: "voltage",
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: string, data: any) => (
          <TableWithGetDataFunctionCell type={stringType} value={value} />
        ),
      },
    },
    {
      label: t("bathUsagePeriod"),
      name: "bup",
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: string, data: any) => (
          <TableWithGetDataFunctionCell type={stringType} value={value} />
        ),
      },
    },
    {
      label: `${t("hydrogenFlux")} (mol/cm2sec)`,
      name: "test_result",
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: string, data: any) => (
          <TableWithGetDataFunctionCell type={stringType} value={value} />
        ),
      },
    },
  ];
};

export const helios3Table = (countLines: any[], setCountLines: any, t: any) => {
  return [
    {
      label: t("selected"),
      name: "session_id",
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: string, data: any) => (
          <div className="cell">
            <Checkbox
              checked={countLines.includes(data.rowData[0])}
              key={data.rowData[0]}
              onChange={(event) => {
                if (event.target.checked !== undefined) {
                  if (event.target.checked) {
                    let tmp = countLines;
                    setCountLines(tmp.concat([data.rowData[0]]));
                  } else {
                    if (countLines.length > 1) {
                      let tmp = countLines;
                      let i = tmp.indexOf(data.rowData[0]);
                      if (i !== -1) {
                        tmp = tmp.filter(function (item) {
                          return item !== tmp[i];
                        });
                      }
                      setCountLines(tmp);
                    } else {
                      setCountLines([]);
                    }
                  }
                }
              }}
            />
          </div>
        ),
      },
    },
    {
      label: t("sampleType"),
      name: "sample_type",
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: string, data: any) => (
          <TableWithGetDataFunctionCell type={stringType} value={value} />
        ),
      },
    },
    {
      label: t("sampleSupplier"),
      name: "sample_supplier",
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: string, data: any) => (
          <TableWithGetDataFunctionCell type={stringType} value={value} />
        ),
      },
    },
    {
      label: `${t("sampleWeight")} (g)`,
      name: "sample_weight",
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: string, data: any) => (
          <TableWithGetDataFunctionCell type={stringType} value={value} />
        ),
      },
    },
    {
      label: `${t("testTemperature")} (°C)\n ${t("heatingRate")} (°C'min)`,
      name: "test_result",
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: string, data: any) => (
          <TableWithGetDataFunctionCell type={stringType} value={value} />
        ),
      },
    },
  ];
};

export const helios4HotProbeTable = (
  countLines: any[],
  setCountLines: any,
  t: any
) => {
  return [
    {
      label: t("selected"),
      name: "session_id",
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: string, data: any) => (
          <div className="cell">
            <Checkbox
              checked={countLines.includes(data.rowData[0])}
              key={data.rowData[0]}
              onChange={(event) => {
                if (event.target.checked !== undefined) {
                  if (event.target.checked) {
                    setCountLines(countLines.concat([data.rowData[0]]));
                  } else {
                    if (countLines.length > 1) {
                      let tmp = countLines;
                      let i = tmp.indexOf(data.rowData[0]);
                      if (i !== -1) {
                        tmp = tmp.filter(function (item) {
                          return item !== tmp[i];
                        });
                      }
                      setCountLines(tmp);
                    } else {
                      setCountLines([]);
                    }
                  }
                }
              }}
            />
          </div>
        ),
      },
    },
    {
      label: t("date"),
      name: "test_date",
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: string, data: any) => (
          <TableWithGetDataFunctionCell
            type={stringType}
            value={localeDateFormatter(value)}
          />
        ),
      },
    },
    {
      label: t("testType"),
      name: "test_type",
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: string, data: any) => (
          <TableWithGetDataFunctionCell type={stringType} value={value} />
        ),
      },
    },
    {
      label: t("supplier"),
      name: "supplier",
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: string, data: any) => (
          <TableWithGetDataFunctionCell type={stringType} value={value} />
        ),
      },
    },
    {
      label: `${t("targetTemperature")} (°C)`,
      name: "target_temperature",
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: string, data: any) => (
          <TableWithGetDataFunctionCell type={stringType} value={value} />
        ),
      },
    },
    {
      label: t("specimenMaterial"),
      name: "specimen_material",
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: string, data: any) => (
          <TableWithGetDataFunctionCell type={stringType} value={value} />
        ),
      },
    },
    {
      label: t("specimenThickness"),
      name: "steel_thickness",
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: string, data: any) => (
          <TableWithGetDataFunctionCell type={stringType} value={value} />
        ),
      },
    },
    {
      label: t("coatingThickness"),
      name: "coating_thickness",
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: string, data: any) => (
          <TableWithGetDataFunctionCell type={stringType} value={value} />
        ),
      },
    },
    {
      label: `${t("hydrogenContent")} (ppmw)`,
      name: "test_result",
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: string, data: any) => (
          <TableWithGetDataFunctionCell type={stringType} value={value} />
        ),
      },
    },
  ];
};
