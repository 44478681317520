import React, { useEffect, useState } from "react";
import "../../navbar/Navbar.css";
import "./alarmTable.css";
import "../../../App.css";
import CustomTitle from "../../CustomTitle";
import TableWithGetDataFunction from "../../tableWithGetDataFunction";
import { alarmColumns } from "./columns";
import { getAlarms } from "../../../api/services/timeSeries";
import { useTranslation } from "react-i18next";

const AlarmTable: React.FC = () => {
  const { t } = useTranslation();

  const [alarms, setAlarms] = useState<any>(null);
  useEffect(() => {
    getAlarms().then((res) => {
      if (res && !res.err) {
        setAlarms(res.alarms);
      }
    });
  }, []);

  return (
    <>
      <CustomTitle title={t("alarms")} />
      <TableWithGetDataFunction columns={alarmColumns(t)} data={alarms} />
    </>
  );
};

export default AlarmTable;
