// types string constants
export const stringType = "string";
export const numberType = "number";
export const linkType = "link";
export const tagType = "tag";
export const dateType = "date";
export const dateTimeType = "dateTime";
export const iconType = "icon";
export const statusDate = "statusDate";
// Dates formatters
export const italyTimezone = "it-IT";

export const localeDateFormatter = (
  value: string,
  timezone: string = "it-IT"
) => {
  const timestamp = Date.parse(value);
  if (!isNaN(timestamp)) {
    return new Date(value).toLocaleDateString(timezone);
  } else {
    return "---";
  }
};

export const localeDateTimeFormatter = (
  value: string,
  timezone: string = "it-IT"
) => {
  const timestamp = Date.parse(value);
  if (!isNaN(timestamp)) {
    return new Date(value).toLocaleString(timezone);
  } else {
    return "---";
  }
};

export const rowsPerTablePage = 20;

export const checkModel = (model: string) => {
  let result: string = model;
  if (model === "helios_2") {
    result = "Helios 2";
  }
  if (model === "helios_2_galv") {
    result = "Helios 2 Galv";
  }
  if (model === "helios_3") {
    result = "Helios 3";
  }
  if (model === "helios_4") {
    result = "Helios 4 Hot Probe";
  }
  if (model === "helios_4_corrosion") {
    result = "Helios 4 Corrosion";
  }
  if (model === "helios_magma") {
    result = "Helios Magma";
  }
  return result;
};
