import {
  TableWithGetDataFunctionCell,
  ResponsiveTableColumnType,
} from "../../../tableWithGetDataFunction";
import "../../../../App.css";
import { dateTimeType, numberType } from "../../../../utilities/utilities";

export const tabValuesColumns: (t: any) => ResponsiveTableColumnType[] = (
  t: any
) => [
  {
    label: t("data"),
    name: "ts",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <TableWithGetDataFunctionCell type={dateTimeType} value={value} />
      ),
    },
  },
  {
    label: t("value"),
    name: "value",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <TableWithGetDataFunctionCell type={numberType} value={value} />
      ),
    },
  },
];
