import "antd/dist/antd.css";
import { Popconfirm, Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

type ButtonConfProps = {
  buttonType?: "text" | "link" | "ghost" | "default" | "primary" | "dashed";
  buttonLabel: string;
  buttonOnConfirm: any;
  buttonOnCancel: any;
  questionLabel?: string;
  yesLabel?: string;
  noLabel?: string;
};

const ButtonConfItem: React.FC<ButtonConfProps> = ({
  buttonType,
  buttonLabel,
  buttonOnConfirm,
  buttonOnCancel,
  questionLabel,
  yesLabel,
  noLabel,
}) => {
  const { t } = useTranslation();

  return (
    <Popconfirm
      title={questionLabel ?? t("sureQuestion")}
      onConfirm={buttonOnConfirm}
      onCancel={buttonOnCancel}
      okText={yesLabel ?? t("yes")}
      cancelText={noLabel ?? t("no")}
    >
      <Button size="large" type={buttonType ?? "default"}>
        {buttonLabel}
      </Button>
    </Popconfirm>
  );
};

export default ButtonConfItem;
