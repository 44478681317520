export const idParam = ":id";
export const nameParam = ":name";

export const resetPasswordPageUrlResult = () => `/reset-result`;
export const recoverPageUrlResult = () => `/recover-result`;
export const mainUrl = () => `/`;
export const mainPageUrl = () => `/profile`;
export const loginPageUrl = () => `/login`;
export const resetPasswordPageUrl = () => `/recover`;
export const recoverPageUrl = () => `/auth/recover/end`;
export const createCustomerPageUrl = () => `/customers/new`;
export const customersListPageUrl = () => `/customers`;
export const updateUserPageUrl = (id: string) => `/users/update/${id}`;
export const createSubscriptionPageUrl = () => `/subscriptions/new`;
export const installationPageUrl = (id: string) => `/installations/${id}`;
export const subscriptionsListToInstallationPageUrl = () =>
  `/data/subscriptions`;
export const installationListToLineChartUrl = (id: string) =>
  `/data/subscriptions/${id}/installations`;
export const subscriptionsListToUpdateUrl = () => `/subscriptions/update`;
export const updateSubscriptionUrl = (id: string) =>
  `/subscriptions/update/${id}`;
export const subscriptionsListToDataHistoryPageUrl = () =>
  `/history/subscriptions`;
export const installationListToDataHistoryUrl = (id: string) =>
  `/history/subscriptions/${id}/installations`;
export const dataHistoryPanelUrl = (id: string) =>
  `/history/subscriptions/installations/${id}`;
export const createInstallationUrl = () => `/new_installation`;
export const installationsListToUpdateUrl = () =>
  `/subscriptions/installations`;
export const updateInstallationUrl = (id: string) =>
  `/installations/${id}/update`;
export const alarmsPageUrl = () => `/alarms`;
export const deviceConnectionConfigurationUrl = (id: string) =>
  `/installations/${id}/configuration`;
export const successUrl = () => `/success`;
export const subscriptionsListToReportsPageUrl = () => `/report/subscriptions`;
export const installationListToReportsPageUrl = (id: string) =>
  `/report/subscriptions/${id}/installations`;
export const ReportsInstallationUrl = (id: string) =>
  `/installations/${id}/report`;
export const installationTableUrl = (id: string) =>
  `/installations/${id}/result`;

export const routes = {
  mainPage: mainUrl(),
  login: loginPageUrl(),
  recoverStart: resetPasswordPageUrl(),
  recoverEnd: recoverPageUrl(),
  resetResult: resetPasswordPageUrlResult(),
  recoverResult: recoverPageUrlResult(),
  profile: mainPageUrl(),
  addCustomer: createCustomerPageUrl(),
  installationPage: installationPageUrl(idParam),
  customersList: customersListPageUrl(),
  updateUser: updateUserPageUrl(idParam),
  installationsListToLineChart: installationListToLineChartUrl(idParam),
  installationsListToDataHistory: installationListToDataHistoryUrl(idParam),
  addSubscription: createSubscriptionPageUrl(),
  subscriptionsListToUpdate: subscriptionsListToUpdateUrl(),
  updateSubscription: updateSubscriptionUrl(idParam),
  subscriptionsListToDataHistory: subscriptionsListToDataHistoryPageUrl(),
  dataHistoryPanel: dataHistoryPanelUrl(idParam),
  subscriptionsListToInstallationPage: subscriptionsListToInstallationPageUrl(),
  addInstallation: createInstallationUrl(),
  installationsListToUpdate: installationsListToUpdateUrl(),
  updateInstallation: updateInstallationUrl(idParam),
  alarms: alarmsPageUrl(),
  deviceConnectionConfiguration: deviceConnectionConfigurationUrl(idParam),
  success: successUrl(),
  subscriptionsListToReports: subscriptionsListToReportsPageUrl(),
  installationListToReports: installationListToReportsPageUrl(idParam),
  reportsInstallation: ReportsInstallationUrl(idParam),
  installationTable: installationTableUrl(idParam),
};

export const roles = {
  admin: "admin",
  customer: "customer",
  device: "device",
};

export const maxDataChart: number = 50;

export const tabList = (t: any) => [
  {
    key: "avg",
    tab: t("avg"),
  },
  {
    key: "min",
    tab: t("min"),
  },
  {
    key: "max",
    tab: t("max"),
  },
];

export const lineColors = {
  avg: "#8884D8",
  min: "#008000",
  max: "#FF6347",
  s2: "#D773A2",
  s3: "#07BB9C",
  s4: "#FBC740",
  s5: "#A06AB4",
  s6: "#E34234",
  s7: "#5E376D",
  s8: "#FBC740",
};

export const checkbox = [
  { label: "avg", value: "avg" },
  { label: "max", value: "max" },
  { label: "min", value: "min" },
];
