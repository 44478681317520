import React from "react";
import { LeftCircleTwoTone } from "@ant-design/icons";
import Title from "antd/es/typography/Title";
import "../App.css";
import { Divider } from "antd";
import { useHistory } from "react-router-dom";

type TitleBackProps = {
  title: string;
};

const TitleWithBack: React.FC<TitleBackProps> = ({ title }) => {
  const history = useHistory();
  return (
    <>
      <div className={"titleContainer titleContainerResponsive"}>
        <Title>
          <LeftCircleTwoTone
            className="back-button"
            onClick={() => {
              history.goBack();
            }}
          />
        </Title>
        <Title className="titleBack" level={2}>
          {title}
        </Title>
      </div>
      <Divider />
    </>
  );
};

export default TitleWithBack;
