import { Action } from "../actions";
import { ChartObject, DataChart } from "../api/requests/timeSeries";
import { Aggregation } from "../actions/lineChart";
import { maxDataChart } from "../components/pages/costants";

export type GlobalState = {
  data_chart: { [s: string]: DataChart };
  interval_id: number;
  last_ts: Date;
};

let d = new Date();
d.setHours(d.getHours() - 24);

export const initialState: GlobalState = {
  data_chart: {
    summary: {
      data: {},
      aggregations: ["s2", "s3", "s4", "s5", "s6", "s7", "s8"],
      zoom: {
        data: {},
        left: "",
        right: "",
        refAreaLeft: "",
        refAreaRight: "",
        top: "dataMax+1",
        bottom: "dataMin-1",
        animation: true,
        isZoomed: false,
      },
      last_ts: d,
    },
  },
  interval_id: 0,
  last_ts: d,
};

const chartDataObjectGenerator = (data: ChartObject, tmpData: ChartObject) => {
  const data_chart = {
    raw: [...(data?.raw || []), ...(tmpData?.raw || [])],
    avg: [...(data?.avg || []), ...(tmpData?.avg || [])],
    min: [...(data?.min || []), ...(tmpData?.min || [])],
    max: [...(data?.max || []), ...(tmpData?.max || [])],
    s1: [...(data?.s1 || []), ...(tmpData?.s1 || [])],
    s2: [...(data?.s2 || []), ...(tmpData?.s2 || [])],
    s3: [...(data?.s3 || []), ...(tmpData?.s3 || [])],
    s4: [...(data?.s4 || []), ...(tmpData?.s4 || [])],
    s5: [...(data?.s5 || []), ...(tmpData?.s5 || [])],
    s6: [...(data?.s6 || []), ...(tmpData?.s6 || [])],
    s7: [...(data?.s7 || []), ...(tmpData?.s7 || [])],
    s8: [...(data?.s8 || []), ...(tmpData?.s8 || [])],
  };

  for (const [key, value] of Object.entries(data_chart)) {
    if (value && value.length > maxDataChart) {
      data_chart[key as Aggregation] = value.filter(
        (el) => value.indexOf(el) > value.length - maxDataChart
      );
    }
    data_chart[key as Aggregation] = value.map((el) => {
      return {
        ts: new Date(el.ts),
        value: parseFloat(el.value.toFixed(2)),
      };
    });
  }
  return data_chart;
};

const dataChartReducer = (
  state: GlobalState = initialState,
  action: Action
) => {
  switch (action.type) {
    case "SET_AGGREGATIONS":
      const item = {
        ...state.data_chart[action.key],
        aggregations: action.aggregations,
      };
      return {
        ...state,
        data_chart: {
          ...state.data_chart,
          [action.key]: item,
        },
      };
    case "ADD_DATA":
      let tmp: ChartObject = {};
      let data: ChartObject = {
        avg: [],
        max: [],
        min: [],
      };

      if (state.data_chart[action.key] !== undefined) {
        data = state.data_chart[action.key].data;
      }

      for (const [key, value] of Object.entries(action.data)) {
        if (value && Array.isArray(value) && value.length > 0) {
          tmp[key as Aggregation] = state.last_ts
            ? value.filter((row) => new Date(row.ts) > new Date(state.last_ts))
            : value;
        }
      }
      const temp_chart = chartDataObjectGenerator(data, action.data);
      if (state.data_chart[action.key] === undefined) {
        const item = {
          data: temp_chart,
          aggregations: ["max", "min", "avg"],
          zoom: {
            data: {},
            left: "",
            right: "",
            refAreaLeft: "",
            refAreaRight: "",
            top: "dataMax+1",
            bottom: "dataMin-1",
            animation: true,
            isZoomed: false,
          },
        };
        return {
          ...state,
          last_ts: action?.lastTimestamp
            ? new Date(action?.lastTimestamp)
            : state.last_ts,
          data_chart: {
            ...state.data_chart,
            [action.key]: item,
          },
        };
      } else {
        const item = {
          data: temp_chart,
          aggregations: state.data_chart[action.key].aggregations,
          zoom: state.data_chart[action.key].zoom,
        };
        return {
          ...state,
          last_ts: new Date(action.lastTimestamp),
          data_chart: {
            ...state.data_chart,
            [action.key]: item,
          },
        };
      }
    case "SET_ZOOM":
      const element = {
        data: state.data_chart[action.key]?.data || [],
        aggregations: state.data_chart[action.key]?.aggregations || [],
        zoom: { ...state.data_chart[action.key].zoom, ...action.zoomUpd },
      };
      return {
        ...state,
        data_chart: {
          ...state.data_chart,
          [action.key]: element,
        },
      };
    case "SET_INTERVAL":
      return {
        ...state,
        interval_id: action.interval,
      };
    case "CLEAR_INTERVAL":
      clearInterval(state.interval_id);
      let tmp123 = { ...state.data_chart };
      for (let [key, value] of Object.entries(tmp123)) {
        value.data = chartDataObjectGenerator({}, {});
        value.aggregations = ["max", "min", "avg"];
        value.zoom = {
          data: {},
          left: "",
          right: "",
          refAreaLeft: "",
          refAreaRight: "",
          top: "dataMax+1",
          bottom: "dataMin-1",
          animation: true,
          isZoomed: false,
        };
      }
      return {
        ...initialState,
        data_chart: tmp123,
      };
    case "SET_DATA_START_DATE":
      return {
        ...state,
        last_ts: action.start_date,
      };
    default:
      return state;
  }
};

export default dataChartReducer;
