import { InputType } from "../../types/form/types";
import { UserOutlined } from "@ant-design/icons";
import { emailRules } from "../../types/form/rules";

export const inputs: (t: any) => InputType[] = (t: any) => [
  {
    key: "email",
    name: "email",
    label: t("email"),
    type: "email",
    icon: <UserOutlined className="site-form-item-icon" />,
    rules: emailRules("email", t),
  },
];
