import "./DataHistoryPanel.css";
import React, { useCallback, useEffect, useState } from "react";
import { DatePicker, Form, message } from "antd";
import { Installation } from "../../../api/requests/installationsService";
import ButtonConfItem from "../../ButtonConfItem";
import ButtonItem from "../../ButtonItem";
import TitleWithBack from "../../../containers/TitleWithBack";
import InstallationSummary from "../../../containers/installationSummary/InstallationSummary";
import { getInstallation } from "../../../api/services/installationsService";
import moment from "moment";
import { getExportInfo } from "../../../api/services/timeSeries";
import { GetExportInfoRequest } from "../../../api/requests/timeSeries";
import { useTranslation } from "react-i18next";

type DataHistoryPanelProps = {
  installation_id: string;
};

const DataHistoryPanel: React.FC<DataHistoryPanelProps> = ({
  installation_id,
}) => {
  const { t } = useTranslation();

  const [selectedInstallation, setSelectedInstallation] =
    useState<Installation | null>(null);
  const [form] = Form.useForm();

  useEffect(() => {
    getInstallation({ id: installation_id }).then((res) => {
      if (res && res.installation) {
        setSelectedInstallation(res.installation);
      }
    });
  }, []);

  const downloadFile = useCallback((url: string) => {
    // Create a link element
    const link = document.createElement("a");

    // Set link's href to point to the Blob URL
    link.href = url;
    link.download = "download.csv";

    // Append link to the body
    document.body.appendChild(link);

    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );

    // Remove link from body
    document.body.removeChild(link);
  }, []);

  const dateFormatter = (date: Date) => {
    return moment(date).format("YYYY-MM-DD");
  };

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const start = new Date(values.date[0]);
      const end = new Date(values.date[1]);
      var differenceInTime = end.getTime() - start.getTime();
      var differenceInDays = differenceInTime / (1000 * 3600 * 24);
      if (differenceInDays <= 6) {
        const req = {
          start: dateFormatter(start),
          end: dateFormatter(end),
          tag: "data",
          entity_id: installation_id,
        } as GetExportInfoRequest;
        getExportInfo(req)
          .then((resp) => {
            if (resp && resp.url) {
              downloadFile(resp.url);
            } else if (resp && resp.err) {
              message.error(resp.err.message);
            } else {
              message.error("failed to download file - maybe no data");
            }
          })
          .catch((e) => {
            message.error("failed to download file");
          });
      } else {
        message.error("Possono essere selezionati massimo 7 giorni.");
      }
    });
  };

  if (!selectedInstallation) {
    return null;
  }

  return (
    <>
      <TitleWithBack title={t("dataHistory")} key="data_history_title" />
      <InstallationSummary selected_installation={selectedInstallation} />
      <div className="my-data-container my-data-container-responsive">
        <Form
          className="exportForm"
          layout="vertical"
          key={1}
          form={form}
          name="datahistory_panel"
        >
          <Form.Item
            name="date"
            label={t("selectDates")}
            rules={[{ required: true, message: `${t("requiredField")}` }]}
          >
            <DatePicker.RangePicker className="datepicker" size="large" />
          </Form.Item>
          <div className="btn-container">
            <ButtonConfItem
              buttonLabel={t("reset")}
              buttonOnConfirm={() => form.resetFields()}
              buttonOnCancel={() => {}}
              questionLabel={`${t("areYouSure")}`}
            />
            <ButtonItem
              buttonType="primary"
              label={t("ok")}
              buttonOnClick={handleSubmit}
            />
          </div>
        </Form>
      </div>
    </>
  );
};

export default DataHistoryPanel;
