import { installationFields, installationModels } from "./inputs";
import ButtonItem from "../../ButtonItem";
import ButtonConfItem from "../../ButtonConfItem";
import { Form, message, Select } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { CreateInstallation } from "../../../api/services/installationsService";
import { Subscription } from "../../../api/requests/subscription";
import { getAllSubscriptions } from "../../../api/services/subscriptionService";
import CustomTitle from "../../CustomTitle";
import "./AddInstallation.css";
import FormInput from "../../input/FormInput";
import { InstallationsServiceUrl } from "../../../api/constants";
import { useTranslation } from "react-i18next";

const { Option } = Select;

type AddInstallationProps = {};

const AddInstallation: React.FC<AddInstallationProps> = () => {
  const { t } = useTranslation();
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);

  const donwloadPackage = useCallback((installation_id: string) => {
    // Create a link element
    const link = document.createElement("a");

    // Set link's href to point to the Blob URL
    link.href = `${InstallationsServiceUrl}/${installation_id}/package`;
    link.download = "download.csv";

    // Append link to the body
    document.body.appendChild(link);

    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );
    // Remove link from body
    document.body.removeChild(link);
  }, []);

  useEffect(() => {
    getAllSubscriptions("", "", "").then((res) => {
      if (res && res.subscriptions) {
        setSubscriptions(res.subscriptions);
      }
    });
  }, []);

  const [form] = Form.useForm();

  const submit = () => {
    form.validateFields().then((values) => {
      CreateInstallation({
        name: values.name,
        subscription_id: values.subscription_id ?? "",
        model:
          values.model === "Helios 4 Hot Probe"
            ? "helios_4"
            : String(values.model).toLowerCase().split(" ").join("_") ?? "",
        description: values.description,
      }).then((res) => {
        if (res && res.installation) {
          message.success(t("createInstallationSuccess"), 5);
          form.resetFields();
          donwloadPackage(res.installation.id);
        } else {
          message.error(res?.err?.message ?? t("createInstallationError"), 5);
        }
      });
    });
  };

  return (
    <>
      <CustomTitle
        title={t("createInstallation")}
        key={"add_installation_title"}
      />
      <div
        className="my-addinstallation-container my-addinstallation-container-responsive"
        key="add_installation_div"
      >
        <Form layout="vertical" key={1} form={form} name="user_panel">
          <Form.Item
            label={t("selectSubscription")}
            name="subscription_id"
            key="select_subscription"
            rules={[{ required: true, message: `${t("selectSubscription")}` }]}
          >
            <Select placeholder="..." key="select_subscription">
              {subscriptions.map((el, index) => {
                return (
                  <Option value={el.id} key={index}>
                    {el.company_info.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Seleziona un modello"
            name="model"
            key="select_model"
          >
            <Select placeholder="..." key="select_model">
              {installationModels.map((opt: string, index: number) => {
                return (
                  <Option key={index} value={opt}>
                    {opt}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          {installationFields(t).map((el) => (
            <FormInput
              key={el.key + "forminput"}
              value={""}
              name={el.name}
              keyValue={el.key}
              placeholder={el.label}
              type={el.type ?? "text"}
              rules={el?.rules ?? []}
            />
          ))}
          <div className="btn-container" key="add_installation_btn">
            <ButtonConfItem
              buttonLabel={t("reset")}
              buttonOnConfirm={() => {
                form.resetFields();
              }}
              buttonOnCancel={() => {}}
              questionLabel={`${t("areYouSure")}`}
            />
            <ButtonItem
              buttonType="primary"
              label={t("ok")}
              buttonOnClick={submit}
            />
          </div>
        </Form>
      </div>
    </>
  );
};

export default AddInstallation;
