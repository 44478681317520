import "./summary.css";
import React from "react";
import { Descriptions } from "antd";
import { Installation } from "../../api/requests/installationsService";
import { useTranslation } from "react-i18next";

type InstallationSummaryProps = {
  selected_installation: Installation | null;
};

const InstallationSummary: React.FC<InstallationSummaryProps> = ({
  selected_installation,
}) => {
  const { t } = useTranslation();

  return (
    <div className="description">
      <Descriptions
        labelStyle={{ fontWeight: "bold" }}
        style={{ marginTop: "2%", marginBottom: "2%" }}
        title={t("installation")}
        bordered
      >
        <Descriptions.Item label={t("name")} span={1.5}>
          {selected_installation?.name || ""}
        </Descriptions.Item>
        <Descriptions.Item label={t("information")} span={1.5}>
          {selected_installation?.installations_info || ""}
        </Descriptions.Item>
        <Descriptions.Item label={t("creationDate")} span={1.5}>
          {selected_installation && selected_installation.created_at
            ? new Date(selected_installation.created_at).toLocaleDateString()
            : ""}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default InstallationSummary;
