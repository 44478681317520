import { ChartObject, GetDataRequest } from "../api/requests/timeSeries";
import { getData } from "../api/services/timeSeries";
import { ZoomUpdates } from "../components/types/timeseries/types";
import { machineVariable } from "../components/pages/installation/costant";

export type SET_AGGREGATIONS = "SET_AGGREGATIONS";
export type ADD_DATA = "ADD_DATA";
export type SET_ZOOM = "SET_ZOOM";
export type SET_INTERVAL = "SET_INTERVAL";
export type CLEAR_INTERVAL = "CLEAR_INTERVAL";
export type SET_DATA_START_DATE = "SET_DATA_START_DATE";

export type Aggregation =
  | "avg"
  | "max"
  | "min"
  | "raw"
  | "s1"
  | "s2"
  | "s3"
  | "s4"
  | "s5"
  | "s6"
  | "s7"
  | "s8";

export interface SetAggregations {
  type: SET_AGGREGATIONS;
  aggregations: string[];
  key: string;
}

export interface AddChartData {
  type: ADD_DATA;
  data: ChartObject;
  lastTimestamp: Date;
  key: string;
}

export interface SetChartZoom {
  type: SET_ZOOM;
  zoomUpd: ZoomUpdates;
  key: any;
}

export interface SetInterval {
  type: SET_INTERVAL;
  interval: NodeJS.Timeout;
}

export interface ClearInterval {
  type: CLEAR_INTERVAL;
}

export interface setDataStartDate {
  type: SET_DATA_START_DATE;
  start_date: Date;
}

const getRoundedDate = (bucket: string, d = new Date()) => {
  switch (bucket) {
    case "5m":
      d.setMinutes(d.getMinutes() - (d.getMinutes() % 5));
      d.setSeconds(0);
      d.setMilliseconds(0);
      break;
    case "10m":
      d.setMinutes(d.getMinutes() - (d.getMinutes() % 10));
      d.setSeconds(0);
      d.setMilliseconds(0);
      break;
    case "1h":
      d.setMinutes(0);
      d.setSeconds(0);
      d.setMilliseconds(0);
      break;
    case "5h":
      d.setHours(d.getHours() - (d.getHours() % 5));
      d.setMinutes(0);
      d.setSeconds(0);
      d.setMilliseconds(0);
      break;
    case "10h":
      d.setHours(d.getHours() - (d.getHours() % 10));
      d.setMinutes(0);
      d.setSeconds(0);
      d.setMilliseconds(0);
      break;
  }

  return new Date(d);
};

const updateChartData = (
  dev: string,
  a: Aggregation[],
  dispatch: any,
  getState: any,
  tag: string,
  variable: string,
  bucket: string
) => {
  const state = getState();
  const start = state.chart.last_ts;
  const s = getRoundedDate(bucket, start);

  let req: GetDataRequest = {
    device_id: dev,
    tag: `${tag}.${variable}`,
    start: new Date(s).toISOString(),
    end: getRoundedDate(bucket).toISOString(),
    bucket: bucket,
    sort: "ASC",
    aggregation: a,
  };

  getData(req).then((res) => {
    if (res && res.result) {
      dispatch({
        type: "ADD_DATA",
        data: res.result,
        lastTimestamp: res.result.last_ts ?? null,
        key: variable,
      });
    }
  });
};

const startDataUpdater = (
  dispatch: any,
  dev: string,
  a: Aggregation[],
  getState: any,
  period: string,
  interval: number,
  variables: machineVariable[]
) => {
  variables.map((el: machineVariable) => {
    updateChartData(dev, a, dispatch, getState, "data", el.key, period);
  });
  const intervalId = setInterval(() => {
    variables.map((el: machineVariable) => {
      updateChartData(dev, a, dispatch, getState, "data", el.key, period);
    });
  }, interval);
  dispatch({
    type: "SET_INTERVAL",
    interval: intervalId,
  });
};

export const startUpdateChartData =
  (
    dev: string,
    a: Aggregation[],
    period: string,
    interval: number,
    variables: machineVariable[]
  ) =>
  async (dispatch: any, getState: any) => {
    startDataUpdater(dispatch, dev, a, getState, period, interval, variables);
  };

export const setDataStartDate =
  (
    dev: string,
    a: Aggregation[],
    startDate: Date,
    period: string,
    interval: number,
    variables: machineVariable[]
  ) =>
  async (dispatch: any, getState: any) => {
    dispatch({
      type: "SET_DATA_START_DATE",
      start_date: startDate,
    });
    startDataUpdater(dispatch, dev, a, getState, period, interval, variables);
  };

export const setPeriod =
  (
    dev: string,
    a: Aggregation[],
    startDate: Date,
    period: string,
    interval: number,
    variables: machineVariable[]
  ) =>
  async (dispatch: any, getState: any) => {
    dispatch({
      type: "SET_DATA_START_DATE",
      start_date: startDate,
    });
    startDataUpdater(dispatch, dev, a, getState, period, interval, variables);
  };
