import "./resetPassword.css";
import "antd/dist/antd.css";
import ButtonItem from "../../ButtonItem";
import { inputs } from "./inputs";
import { Link, useHistory } from "react-router-dom";
import { Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { resetPassword } from "../../../api/services/loginService";
import { resetPasswordPageUrlResult } from "../costants";
import logo from "../../../logo/Logo_LETOMEC_Orizzontale_1.png";
import { useTranslation } from "react-i18next";

const ResetPassword: React.FC = () => {
  const { t } = useTranslation();

  document.body.style.backgroundColor = "#011529";
  const [width, setWidth] = useState<number>(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const [form] = Form.useForm();

  const history = useHistory();
  const submit = () => {
    form.validateFields().then((values) => {
      resetPassword({
        email: values.email,
      }).then((res) => {
        if (res && !res.err) {
          history.push(resetPasswordPageUrlResult());
        }
      });
    });
  };

  return (
    <div className={width <= 768 ? "reset-box-responsive" : "reset-box"}>
      <div className="image-container">
        <img alt="image" src={logo} className="image" />
      </div>
      <Form name="normal_login" form={form}>
        <h2> {t("resetPassword")} </h2>
        {inputs(t).map((el) => {
          return (
            <Form.Item key={el.key} name={el.name} rules={el.rules}>
              <Input
                size="large"
                type={el.type ?? "text"}
                prefix={el.icon}
                placeholder={el.label}
              />
            </Form.Item>
          );
        })}
        <Link className="reset-form-forgot" to="/login">
          {t("backToLogin")}
        </Link>
        <div className="reset-btn-container">
          <Form.Item>
            <ButtonItem
              buttonType="primary"
              label={t("reset")}
              buttonOnClick={submit}
            />
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default ResetPassword;
