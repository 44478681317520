import React, { useEffect } from "react";
import MUIDataTable, { Responsive } from "mui-datatables";
import "./Table.css";
import CustomTag from "./pages/installation/summaryCards/customTag";
import {
  dateTimeType,
  dateType,
  iconType,
  italyTimezone,
  linkType,
  localeDateFormatter,
  localeDateTimeFormatter,
  numberType,
  rowsPerTablePage,
  statusDate,
  stringType,
  tagType,
} from "../utilities/utilities";
import { Tag } from "antd";
import { useState } from "react";

interface tableWithGetDataFunctionProps {
  title?: string;
  columns: ResponsiveTableColumnType[];
  data?: Object[];
  expandableRows?: boolean;
  renderExpandableRow?: (rowData: any, rowMeta: any) => JSX.Element;
  icon?: boolean;
  getPageAndSortedData?: any;
  defaultSort?: string;
  responseDataName?: string;
  id?: string;
}

interface TableWithGetDataFunctionCellProps {
  type: string;
  value: string;
  color?: string;
  link?: (...args: any[]) => void;
  icon?: React.ReactNode;
}

export type ResponsiveTableColumnType = {
  name: string;
  label: string;
  options: {
    filter: boolean;
    sort: boolean;
    customBodyRender: (a: any, b: any, c: any) => React.ReactNode;
  };
};

export const TableWithGetDataFunctionCell: React.FC<
  TableWithGetDataFunctionCellProps
> = ({ type, value, color, link, icon }) => {
  switch (type) {
    case stringType:
      return <div className="cell"> {value} </div>;
    case tagType:
      return (
        <div className="cell">
          <CustomTag text={value} color={color ?? "primary"} />
        </div>
      );
    case dateTimeType:
      return (
        <div className="cell">
          {localeDateTimeFormatter(value, italyTimezone)}
        </div>
      );
    case dateType:
      return (
        <div className="cell">{localeDateFormatter(value, italyTimezone)}</div>
      );
    case linkType:
      return (
        <a className="cell" onClick={link}>
          {value}
        </a>
      );
    case iconType:
      return (
        <a className="cell" onClick={link}>
          {icon}
        </a>
      );
    case numberType:
      return <div className="cell"> {parseFloat(value).toFixed(2)} </div>;
    case statusDate:
      if (new Date(value).getFullYear() === 1) {
        return (
          <div className="cell">
            <CustomTag color="orange" text="never" />
          </div>
        );
      }
      const minutesDiff = Math.abs(
        (new Date().getTime() - new Date(value).getTime()) / (1000 * 60)
      );
      if (minutesDiff < 10) {
        return (
          <div className="cell">
            <Tag color={"green"}>
              {localeDateTimeFormatter(value, italyTimezone)}
            </Tag>
          </div>
        );
      } else {
        return (
          <div className="cell">
            <Tag color={"red"}>
              {localeDateTimeFormatter(value, italyTimezone)}
            </Tag>
          </div>
        );
      }
    default:
      return <div className="cell"> {value} </div>;
  }
};

const TableWithGetDataFunction: React.FC<tableWithGetDataFunctionProps> = ({
  title,
  columns,
  data,
  expandableRows,
  renderExpandableRow,
  icon,
  getPageAndSortedData,
  defaultSort,
  responseDataName,
  id,
}) => {
  const [tableData, setTableDate] = useState<Object[]>([]);
  const [totalDataLength, setTotalDataLength] = useState<number>(0);

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [currentSort, setCurrentSort] = useState<string>("");
  const [currentSearch, setCurrentSearch] = useState<string>("");

  useEffect(() => {
    if (defaultSort) {
      setCurrentSort(defaultSort);
    }

    if (getPageAndSortedData && responseDataName) {
      if (id) {
        getPageAndSortedData(id, "0", defaultSort!, currentSearch).then(
          (res: Object) => {
            if (res && (res as any)[responseDataName]) {
              setTableDate((res as any)[responseDataName]);
              setTotalDataLength((res as any)["total_count"] ?? 0);
            }
          }
        );
      } else {
        getPageAndSortedData("0", defaultSort!, currentSearch).then(
          (res: Object) => {
            if (res && (res as any)[responseDataName]) {
              setTableDate((res as any)[responseDataName]);
              setTotalDataLength((res as any)["total_count"] ?? 0);
            }
          }
        );
      }
    } else if (data) {
      setTableDate(data);
    }
  }, []);

  const changePage = (page: number) => {
    setCurrentPage(page);

    if (defaultSort) {
      if (id) {
        getPageAndSortedData(id, String(page), currentSort, currentSearch).then(
          (res: any) => {
            if (res && responseDataName && (res as any)[responseDataName]) {
              setTableDate((res as any)[responseDataName]);
            }
          }
        );
      } else {
        getPageAndSortedData(String(page), currentSort, currentSearch).then(
          (res: any) => {
            if (responseDataName && res && (res as any)[responseDataName]) {
              setTableDate((res as any)[responseDataName]);
            }
          }
        );
      }
    }
  };

  const changeSort = (sort: string) => {
    if (id) {
      getPageAndSortedData(id, String(currentPage), sort, currentSearch).then(
        (res: any) => {
          if (res && responseDataName && (res as any)[responseDataName]) {
            setTableDate((res as any)[responseDataName]);
          }
        }
      );
    } else {
      getPageAndSortedData(String(currentPage), sort, currentSearch).then(
        (res: any) => {
          if (responseDataName && res && (res as any)[responseDataName]) {
            setTableDate((res as any)[responseDataName]);
          }
        }
      );
    }
  };

  const changeSearch = (text: string) => {
    setCurrentSearch(text);
    setCurrentPage(0);
    if (id) {
      getPageAndSortedData(id, "0", currentSort, text).then((res: any) => {
        if (res && responseDataName && (res as any)[responseDataName]) {
          setTableDate((res as any)[responseDataName]);
        } else {
          setTableDate([]);
        }
      });
    } else {
      getPageAndSortedData("0", currentSort, text).then((res: any) => {
        if (res && responseDataName && (res as any)[responseDataName]) {
          setTableDate((res as any)[responseDataName]);
        } else {
          setTableDate([]);
        }
      });
    }
  };

  let options: Object = {
    selectableRows: "none" as "none",
    filter: false,
    print: false,
    download: false,
    serverSide: true,
    responsive: "standard" as Responsive,
    isRowSelectable: () => false,
    expandableRows: expandableRows ?? false,
    renderExpandableRow: !!expandableRows ? renderExpandableRow : undefined,
    rowsPerPage: rowsPerTablePage,
    count: totalDataLength,
    rowsPerPageOptions: [],
    onTableChange: (action: string, tableState: any) => {
      switch (action) {
        case "changePage":
          changePage(tableState.page);
          break;
        case "sort":
          let sort;
          if (tableState.sortOrder.direction === "asc") {
            sort = tableState.sortOrder.name;
            setCurrentSort(sort);
          } else {
            sort = `-${tableState.sortOrder.name}`;
            setCurrentSort(sort);
          }
          changeSort(sort);
          break;
        case "search":
          changeSearch(tableState.searchText);
          break;
        default:
          break;
      }
    },
  };

  if (!getPageAndSortedData) {
    options = {
      selectableRows: "none" as "none",
      filter: false,
      print: false,
      download: false,
      responsive: "standard" as Responsive,
      isRowSelectable: () => false,
      expandableRows: expandableRows ?? false,
      renderExpandableRow:
        expandableRows === true ? renderExpandableRow : undefined,
    };
  }

  const hideIcons = {
    selectableRows: "none" as "none",
    isRowSelectable: () => false,
    expandableRows: expandableRows ?? false,
    renderExpandableRow:
      expandableRows === true ? renderExpandableRow : undefined,
    download: false,
    filter: false,
    print: false,
    search: false,
    viewColumns: false,
    responsive: "standard" as Responsive,
  };

  if (icon === false) {
    return (
      <div className="verticalBorder">
        <MUIDataTable
          title={title}
          data={tableData}
          columns={columns}
          options={hideIcons}
        />
      </div>
    );
  } else {
    return (
      <div className="verticalBorder">
        <MUIDataTable
          title={title}
          data={tableData}
          columns={columns}
          options={options}
        />
      </div>
    );
  }
};

export default TableWithGetDataFunction;
