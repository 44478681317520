import { DownloadInstallationReportRequest } from "./requests/reportsService";
import { GetDataRequest, GetExportInfoRequest } from "./requests/timeSeries";
import { rowsPerTablePage } from "../utilities/utilities";

export const BackendUrl = window._env_.API_ENDPOINT!;

export const LoginUrl = `${BackendUrl}/auth/login`;
export const LogoutUrl = `${BackendUrl}/auth/logout`;
export const SetNewPasswordUrl = `${BackendUrl}/auth/recover/end`;
export const ResetPasswordUrl = `${BackendUrl}/auth/recover`;
export const GetProfileUrl = `${BackendUrl}/auth/profile`;

// TimeSeries service
export const TimeSeriesUrl = `${BackendUrl}/timeseries`;

// Installations Service
export const InstallationsServiceUrl = `${BackendUrl}/installations`;
export const GetInstallationsServiceUrl = (
  page: string,
  sort: string,
  search: string
) => {
  return `${BackendUrl}/installations?page=${page}&rows=${rowsPerTablePage}&sort=${sort}&search=${search}`;
};
export const GetAlarmsUrl = `${TimeSeriesUrl}/alarms`;

// Reports Service
export const ReportsServiceUrl = `${BackendUrl}/reports`;

// Users Service
export const UsersServiceUrl = `${BackendUrl}/users`;

// Subscription Service
export const SubscriptionsUrl = `${BackendUrl}/subscriptions`;
export const GetSubscriptionUrl = (subscription_id: string) => {
  return `${BackendUrl}/subscriptions/${subscription_id}`;
};
export const UpdateSubscriptionInfoUrl = (subscription_id: string) => {
  return `${BackendUrl}/subscriptions/${subscription_id}/info`;
};
export const UpdateSubscriptionStatusUrl = (subscription_id: string) => {
  return `${BackendUrl}/subscriptions/${subscription_id}/status`;
};
export const UpdateSubscriptionExpDateUrl = (subscription_id: string) => {
  return `${BackendUrl}/subscriptions/${subscription_id}/exp`;
};
export const GetSubscriptionInstallationsUrl = (
  subscription_id: string,
  page: string,
  sort: string,
  search: string
) => {
  return `${BackendUrl}/subscriptions/${subscription_id}/installations?page=${page}&rows=${rowsPerTablePage}&sort=${sort}&search=${search}`;
};

export const GetDataUrl = (req: GetDataRequest) => {
  const baseUrl = new URL(
    `${TimeSeriesUrl}/data/${req?.device_id}/${req?.tag}`
  );
  baseUrl.searchParams.append("start", req?.start ?? "");
  baseUrl.searchParams.append("end", req?.end ?? "");
  baseUrl.searchParams.append("bucket", req?.bucket ?? "");
  req?.aggregation?.map((value) => {
    baseUrl.searchParams.append("aggregation", value ?? "");
  });
  baseUrl.searchParams.append("bucket_gap", req?.bucket_gap ?? "");
  baseUrl.searchParams.append("sort", req?.sort ?? "");
  baseUrl.searchParams.append("bucket_offset", "yes");
  return baseUrl.toString();
};

export const GetExportInfoUrl = (req: GetExportInfoRequest) => {
  const baseUrl = new URL(`${TimeSeriesUrl}/data/exports`);
  baseUrl.searchParams.append("start", req.start);
  baseUrl.searchParams.append("end", req.end);
  baseUrl.searchParams.append("entity_id", req.entity_id);
  baseUrl.searchParams.append("tag", req.tag);
  return baseUrl.toString();
};

export const DownloadReportInstallationUrl = (
  req: DownloadInstallationReportRequest
) => {
  const baseUrl = new URL(`${ReportsServiceUrl}/${req.report_id}/download`);
  baseUrl.searchParams.append("installation_id", req.installation_id);
  return baseUrl.toString();
};
