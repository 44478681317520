import "./LoginPanel.css";
import "antd/dist/antd.css";
import ButtonItem from "../../ButtonItem";
import { inputs } from "./inputs";
import { Form, Input, message } from "antd";
import { Link, useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { login } from "../../../api/services/loginService";
import { LoginRequest } from "../../../api/requests/loginService";
import { routes } from "../costants";
import logo from "../../../logo/Logo_LETOMEC_Orizzontale_1.png";
import { useTranslation } from "react-i18next";

type LoginPanelProps = {
  SetLogged: (logged: boolean) => void;
};

const LoginPanel: React.FC<LoginPanelProps> = ({ SetLogged }) => {
  const { t } = useTranslation();

  document.body.style.backgroundColor = "#011529";
  const [width, setWidth] = useState<number>(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const setChange = (e: any) => {
    if (e.target.placeholder === "Email") {
      setEmail(e.target.value);
    } else {
      setPassword(e.target.value);
    }
  };

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [logError, setLogError] = useState(false);

  const history = useHistory();
  const checkLogin = () => {
    login({ email: email, password: password } as LoginRequest)
      .then((res) => {
        if (res !== undefined && res.status === "success") {
          SetLogged(true);
          history.push(res?.location ?? "/");
        } else {
          message.error(res?.error || t("wrongCredentials"), 4);
          setLogError(true);
          SetLogged(false);
        }
      })
      .catch((e) => {
        message.error(t("wrongCredentials"), 4);
        setLogError(true);
        SetLogged(false);
        console.log(e);
      });
  };

  return (
    <div className={width <= 768 ? "login-box-responsive" : "login-box"}>
      <div className="image-container">
        <img alt="logo" src={logo} className="image" />
      </div>
      <Form name="normal_login">
        <h2> {t("login")} </h2>
        {inputs(t).map((el) => {
          return (
            <Form.Item
              key={el.key}
              name={el.name}
              rules={el.rules}
              validateStatus={logError ? "error" : "success"}
            >
              <Input
                className="my-input"
                size="large"
                type={el.type ?? "text"}
                prefix={el.icon}
                placeholder={el.label}
                onChange={setChange}
              />
            </Form.Item>
          );
        })}
        <Link className="login-form-forgot" to={routes.recoverStart}>
          {t("resetPassword")}
        </Link>
        <div className="btn-container">
          <Form.Item>
            <ButtonItem
              buttonType="primary"
              label={t("login")}
              buttonOnClick={checkLogin}
            />
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default LoginPanel;
