import {
  CreateIdentityRequest,
  CreateInstallationRequest,
  DeleteIdentityRequest,
  GetJobStatusRequest,
  GetInstallationRequest,
  GetInstallationVariablesRequest,
  SendJobToInstallationRequest,
  SetInstallationVariableRequest,
  UpdateIdentityRequest,
  UpdateInstallationAlarmRequest,
  UpdateInstallationRequest,
} from "../requests/installationsService";
import {
  methodDelete,
  methodGet,
  methodPost,
  methodPut,
  verticalFetch,
} from "./httpRequests";
import {
  GetInstallationsServiceUrl,
  InstallationsServiceUrl,
} from "../constants";

export const CreateInstallation = (r: CreateInstallationRequest) => {
  return verticalFetch(methodPost, InstallationsServiceUrl, JSON.stringify(r));
};

export const GetAllInstallations = (
  page: string,
  sort: string,
  search: string
) => {
  return verticalFetch(
    methodGet,
    GetInstallationsServiceUrl(page, sort, search)
  );
};

export const UpdateInstallation = (r: UpdateInstallationRequest) => {
  return verticalFetch(
    methodPut,
    `${InstallationsServiceUrl}/${r.id}`,
    JSON.stringify(r)
  );
};

export const UpdateInstallationAlarm = (r: UpdateInstallationAlarmRequest) => {
  return verticalFetch(
    methodPut,
    `${InstallationsServiceUrl}/${r.installationId}/alarms`,
    JSON.stringify(r)
  );
};

export const getInstallation = (r: GetInstallationRequest) => {
  return verticalFetch(methodGet, `${InstallationsServiceUrl}/${r.id}`);
};

export const SendJobToInstallation = (r: SendJobToInstallationRequest) => {
  return verticalFetch(
    methodPost,
    `${InstallationsServiceUrl}/${r.id}/jobs/${r.job_name}`,
    JSON.stringify(r)
  );
};

export const GetJobStatus = (r: GetJobStatusRequest) => {
  return verticalFetch(
    methodGet,
    `${InstallationsServiceUrl}/${r.id}/jobs/${r.job_name}`
  );
};

export const SetInstallationVariable = (r: SetInstallationVariableRequest) => {
  return verticalFetch(
    methodPost,
    `${InstallationsServiceUrl}/${r.id}/vars/${r.variable_name}`,
    JSON.stringify(r)
  );
};

export const GetInstallationVariable = (r: GetInstallationVariablesRequest) => {
  return verticalFetch(methodGet, `${InstallationsServiceUrl}/${r.id}/vars`);
};

//Identity
export const CreateIdentity = (
  r: CreateIdentityRequest,
  installation_id: string
) => {
  return verticalFetch(
    methodPost,
    `${InstallationsServiceUrl}/${installation_id}/identities`,
    JSON.stringify(r)
  );
};

export const GetIdentity = (installation_id: string) => {
  return verticalFetch(
    methodGet,
    `${InstallationsServiceUrl}/${installation_id}/identities`
  );
};

export const UpdateIdentity = (
  r: UpdateIdentityRequest,
  installation_id: string,
  dcn: string
) => {
  return verticalFetch(
    methodPut,
    `${InstallationsServiceUrl}/${installation_id}/identities/${dcn}`,
    JSON.stringify(r)
  );
};

export const DeleteIdentity = (r: DeleteIdentityRequest) => {
  return verticalFetch(
    methodDelete,
    `${InstallationsServiceUrl}/${r.installation_id}/identities/${r.dcn}`
  );
};
