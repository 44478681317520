import React, { useEffect, useState } from "react";
import "../../navbar/Navbar.css";
import "../../../App.css";
import TitleWithBack from "../../../containers/TitleWithBack";
import SubscriptionSummary from "../../../containers/pages/SubscriptionSummary";
import { Subscription } from "../../../api/requests/subscription";
import {
  getSubscription,
  getSubscriptionInstallations,
} from "../../../api/services/subscriptionService";
import TableWithGetDataFunction, {
  ResponsiveTableColumnType,
} from "../../tableWithGetDataFunction";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

type InstallationsListProps = {
  subscription_id: string;
  redirectUrl: (id: string) => string;
  columns: (
    linkFunction: (value: string) => void,
    t: any
  ) => ResponsiveTableColumnType[];
};

const InstallationsListSubscriptionSummary: React.FC<
  InstallationsListProps
> = ({ subscription_id, redirectUrl, columns }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const redirectFun: (value: string) => void = (value: string) =>
    history.push(redirectUrl(value));

  const [selectedSubscription, setSelectedSubscription] =
    useState<Subscription | null>(null);
  useEffect(() => {
    getSubscription({ subscription_id }).then((res) => {
      if (res && res.subscription) {
        setSelectedSubscription(res.subscription);
      }
    });
  }, []);

  return (
    <>
      <TitleWithBack title={t("installationList")} />
      {selectedSubscription ? (
        <SubscriptionSummary subscription={selectedSubscription} />
      ) : null}
      <TableWithGetDataFunction
        columns={columns(redirectFun, t)}
        responseDataName="installations"
        id={subscription_id}
        defaultSort="name"
        getPageAndSortedData={getSubscriptionInstallations}
      />
    </>
  );
};

export default InstallationsListSubscriptionSummary;
