import "./summary.css";
import React, { useCallback, useEffect, useState } from "react";
import { Card, Divider } from "antd";
import Title from "antd/es/typography/Title";
import {
  helios2,
  helios2Model,
  helios3,
  helios3Model,
  helios4,
  helios4HotProbeModel,
  heliosCorrosionModel,
  heliosMagma,
  heliosMagmaModel,
} from "../costant";
import { GetDataRequest } from "../../../../api/requests/timeSeries";
import { getData } from "../../../../api/services/timeSeries";
import { raw } from "../../../../actions/constants";
import { useTranslation } from "react-i18next";

type RealTimeSummaryProps = {
  device_id: string;
  model: string;
};

const RealTimeSummary: React.FC<RealTimeSummaryProps> = ({
  device_id,
  model,
}) => {
  const { t } = useTranslation();

  const [data, setData] = useState<any[]>([]);

  const getLastMinuteData = useCallback(() => {
    let d_start = new Date();
    let d_end = new Date();
    d_start.setDate(d_start.getDate() - 1);
    const req: GetDataRequest = {
      device_id,
      tag: "data.*",
      start: d_start.toISOString(),
      end: d_end.toISOString(),
      aggregation: [raw],
      sort: "DESC",
    };
    getData(req).then((res) => {
      if (res && res.result && res.result.raw && res.result.raw.length > 0) {
        setData(res.result.raw);
      }
    });
  }, []);

  useEffect(() => {
    getLastMinuteData();
    const intervalId = setInterval(() => {
      getLastMinuteData();
    }, 60000);
    return () => clearInterval(intervalId);
  }, []);

  const payloadModel = useCallback(() => {
    switch (model) {
      case helios2Model:
        return helios2;
      case helios3Model:
        return helios3;
      case helios4HotProbeModel:
        return helios4;
      case heliosCorrosionModel:
        return helios4;
      case heliosMagmaModel:
        return heliosMagma;
      default:
        return [];
    }
  }, [model]);

  const sections = payloadModel();

  const lastRawData = useCallback(
    (varName: string) => {
      if (data && data.length > 0) {
        return data[0]?.value[varName]
          ? Number(data[0]?.value[varName])?.toFixed(2)
          : "---";
      }
      return "--";
    },
    [data]
  );

  return (
    <Card
      style={{ minHeight: "290px", maxHeight: "290px" }}
      hoverable
      title={<Title level={5}>{t("realTimeValue")}</Title>}
      bordered
      headStyle={{ maxHeight: "4vh" }}
      bodyStyle={{ maxHeight: "25vh", overflow: "auto" }}
    >
      {sections.map((variable, index) => {
        return (
          <div key={`div_${index}`}>
            <div className="variable-container" key={index}>
              <p>{variable.label}</p>
              <p>{lastRawData(variable.key)}</p>
            </div>
            {index < sections.length - 1 ? <Divider /> : null}
          </div>
        );
      })}
    </Card>
  );
};

export default RealTimeSummary;
