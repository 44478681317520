import { stringType } from "../../../utilities/utilities";
import { TableWithGetDataFunctionCell } from "../../tableWithGetDataFunction";
import { InputType } from "../../types/form/types";
import {
  helios2Material,
  helios2TestType,
  helios3TestType,
  helios4HotProbeSampleType,
} from "./costant";

export const helios2Input: (t: any) => InputType[] = (t: any) => [
  {
    key: "testType",
    name: "testType",
    label: t("testType"),
    type: "select",
    options: helios2TestType,
    rules: [{ required: true, message: t("requiredField") }],
  },
  {
    key: "material",
    name: "material",
    label: t("material"),
    type: "multiple",
    options: helios2Material,
  },
  {
    key: "specimenSupplier",
    name: "specimenSupplier",
    label: t("specimenSupplier"),
    type: "multiple",
  },
  {
    key: "specimenThickness",
    name: "specimenThickness",
    type: "multiple",
    label: t("specimenThickness"),
  },
  {
    key: "date",
    label: t("selectTimePeriodRange"),
    rules: [{ required: true, message: t("requiredField") }],
    type: "date",
    name: "date",
  },
];

export const helios2GalvInput: (t: any) => InputType[] = (t: any) => [
  {
    key: "material",
    name: "material",
    label: t("material"),
    type: "select",
    options: [
      { [t("steel")]: t("steel") },
      { [t("highPurityIron")]: t("highPurityIron") },
      { [t("other")]: t("other") },
    ],
    rules: [{ required: true, message: t("requiredField") }],
  },
  {
    key: "specimenSupplier",
    name: "specimenSupplier",
    label: t("specimenSupplier"),
    type: "multiple",
  },
  {
    key: "specimenThickness",
    name: "specimenThickness",
    type: "multiple",
    label: `${t("specimenThickness")} (mm)`,
    rules: [
      ({ getFieldValue }: any) => ({
        validator(value: any) {
          if (value.lenght === 0) {
            return Promise.resolve();
          }
          const res: string[] = getFieldValue("specimenThickness") || [];
          let boolean_check: Boolean = true;
          res.map((el) => {
            if (!/d+/.test(el)) {
              boolean_check = false;
            }
          });
          if (boolean_check) {
            return Promise.resolve();
          } else {
            return Promise.reject(t("numberMustBeEntered"));
          }
        },
      }),
    ],
  },
  {
    key: "bathSupplier",
    name: "bathSupplier",
    label: t("bathSupplier"),
    type: "multiple",
  },
  {
    label: t("bathType"),
    key: "bathType",
    name: "bathType",
    type: "multiple",
  },
  {
    label: t("bathPh"),
    key: "bathPh",
    name: "bathPh",
    type: "multiple",
    rules: [
      ({ getFieldValue }: any) => ({
        validator(value: any) {
          if (value.lenght === 0) {
            return Promise.resolve();
          }
          const res: string[] = getFieldValue("bathPh") || [];
          let boolean_check: Boolean = true;
          res.map((el) => {
            if (!/d+/.test(el) || Number(el) < 0 || Number(el) > 14) {
              boolean_check = false;
            }
          });
          if (boolean_check) {
            return Promise.resolve();
          } else {
            return Promise.reject(t("numberBetween0And14"));
          }
        },
      }),
    ],
  },
  {
    label: `${t("electroliticDensityCurrent")} (mA/cm 2 )`,
    key: "edc",
    name: "edc",
    type: "multiple",
    rules: [
      ({ getFieldValue }: any) => ({
        validator(value: any) {
          if (value.lenght === 0) {
            return Promise.resolve();
          }
          const res: string[] = getFieldValue("edc") || [];
          let boolean_check: Boolean = true;
          res.map((el) => {
            if (!/d+/.test(el) || Number(el) < 0 || Number(el) > 200) {
              boolean_check = false;
            }
          });
          if (boolean_check) {
            return Promise.resolve();
          } else {
            return Promise.reject(t("numberBetween0And200"));
          }
        },
      }),
    ],
  },
  {
    label: `${t("electronicVoltage")} (V)`,
    key: "electronicVoltage",
    name: "electronicVoltage",
    type: "multiple",
    rules: [
      ({ getFieldValue }: any) => ({
        validator(value: any) {
          if (value.lenght === 0) {
            return Promise.resolve();
          }
          const res: string[] = getFieldValue("electronicVoltage") || [];
          let boolean_check: Boolean = true;
          res.map((el) => {
            if (!/d+/.test(el) || Number(el) < 0 || Number(el) > 10) {
              boolean_check = false;
            }
          });
          if (boolean_check) {
            return Promise.resolve();
          } else {
            return Promise.reject(t("numberBetween0And10"));
          }
        },
      }),
    ],
  },
  {
    label: t("bathUsagePeriod"),
    key: "bup",
    name: "bup",
    type: "multiple",
    rules: [
      ({ getFieldValue }: any) => ({
        validator(value: any) {
          if (value.lenght === 0) {
            return Promise.resolve();
          }
          const res: string[] = getFieldValue("bup") || [];
          let boolean_check: Boolean = true;
          res.map((el) => {
            if (!/d+/.test(el)) {
              boolean_check = false;
            }
          });
          if (boolean_check) {
            return Promise.resolve();
          } else {
            return Promise.reject(t("numberMustBeEntered"));
          }
        },
      }),
    ],
  },
  {
    key: "date",
    label: t("selectTimePeriodRange"),
    rules: [{ required: true, message: t("requiredField") }],
    type: "date",
    name: "date",
  },
];

export const helios3Input: (t: any) => InputType[] = (t: any) => [
  {
    key: "testType",
    name: "testType",
    label: t("testType"),
    type: "select",
    options: helios3TestType,
    rules: [{ required: true, message: "Selezionare tipo di test!" }],
  },
  {
    key: "sampleType",
    name: "sampleType",
    label: t("sampleType"),
    type: "multiple",
  },
  {
    key: "sampleSupplier",
    name: "sampleSupplier",
    label: t("sampleSupplier"),
    type: "multiple",
  },
  {
    key: "date",
    label: t("selectTimePeriodRange"),
    rules: [{ required: true, message: t("requiredField") }],
    type: "date",
    name: "date",
  },
];

export const helios4HotProbeInput: (
  hidden: boolean,
  setHidden: React.Dispatch<React.SetStateAction<boolean>>,
  t: any
) => InputType[] = (hidden, setHidden, t) => [
  {
    key: "testType",
    name: "testType",
    label: t("testType"),
    type: "select",
    options: [
      { [t("costantTemperature")]: t("costantTemperature") },
      {
        [t("temperatureProgrammedDesorption")]: t(
          "temperatureProgrammedDesorption"
        ),
      },
    ],
    rules: [{ required: true, message: t("requiredField") }],
  },
  {
    key: "sampleType",
    name: "sampleType",
    label: t("sampleType"),
    type: "multiple",
    options: helios4HotProbeSampleType,
    rules: [
      ({ getFieldValue }: any) => ({
        validator(value: any) {
          if (value.lenght === 0) {
            return Promise.resolve();
          }
          const res: string[] = getFieldValue("sampleType") || [];

          if (res.includes(t("other"))) {
            setHidden(false);
            if (res.length === 1) {
              return Promise.resolve();
            } else {
              return Promise.reject(t("notOtherValueIfOther"));
            }
          } else {
            setHidden(true);
            if (res.length > 2) {
              return Promise.reject(t("onlyDefaultValue"));
            } else {
              let boolean_check: Boolean = true;
              res.map((el) => {
                if (!el.includes("usibor")) {
                  boolean_check = false;
                }
              });
              if (boolean_check) {
                return Promise.resolve();
              } else {
                return Promise.reject(t("onlyDefaultValue"));
              }
            }
          }
        },
      }),
    ],
  },
  {
    key: "supplier",
    name: "supplier",
    label: t("supplier"),
    type: "multiple",
  },
  {
    key: "specimenMaterial",
    name: "specimenMaterial",
    label: t("specimenMaterial"),
    type: "multiple",
    hidden: hidden,
  },
  {
    key: "specimenThickness",
    name: "specimenThickness",
    label: t("specimenThickness"),
    type: "multiple",
    hidden: hidden,
  },
  {
    key: "coatingThickness",
    name: "coatingThickness",
    label: t("coatingThickness"),
    type: "multiple",
    hidden: hidden,
  },
  {
    key: "date",
    label: t("selectTimePeriodRange"),
    rules: [{ required: true, message: t("requiredField") }],
    type: "date",
    name: "date",
  },
];

export const tabColumns = (t: any) => [
  {
    label: t("data"),
    name: "data",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <TableWithGetDataFunctionCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("value"),
    name: "value",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <TableWithGetDataFunctionCell type={stringType} value={value} />
      ),
    },
  },
];

export const testTabColumns = (t: any) => [
  {
    label: t("data"),
    name: "ts",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <TableWithGetDataFunctionCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: t("value"),
    name: "value",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <TableWithGetDataFunctionCell type={stringType} value={value} />
      ),
    },
  },
];
