import { InputType } from "../../types/form/types";
import { emailRules } from "../../types/form/rules";

export const userFields: (t: any) => InputType[] = (t: any) => [
  {
    key: "name",
    name: "name",
    label: t("name"),
    rules: [{ required: true, message: t("requiredField") }],
  },
  {
    key: "email",
    name: "email",
    label: "Email",
    rules: emailRules("email", t),
  },
  {
    key: "phone",
    name: "phone",
    label: t("phone"),
    path: "phone",
    type: "number",
    rules: [{ required: true, message: t("requiredField") }],
  },
  {
    key: "address",
    name: "address",
    label: t("address"),
    path: "address",
    rules: [{ required: true, message: t("requiredField") }],
  },
  {
    key: "city",
    name: "city",
    label: t("city"),
    path: "city",
    rules: [{ required: true, message: t("requiredField") }],
  },
  {
    key: "province",
    name: "province",
    label: t("province"),
    path: "province",
    rules: [{ required: true, message: t("requiredField") }],
  },
  {
    key: "country",
    name: "country",
    label: t("country"),
    path: "country",
    type: "select",
    rules: [{ required: true, message: t("requiredField") }],
  },
  {
    key: "zip_code",
    name: "zip_code",
    label: t("zipCode"),
    path: "zip_code",
    rules: [{ required: true, message: t("requiredField") }],
  },
];
