import "../charts/lineChart/LineChart.css";
import { DataChart } from "../../../../api/requests/timeSeries";
import React from "react";
import { Tabs } from "antd";
import { Aggregation } from "../../../../actions/lineChart";
import TableWithGetDataFunction from "../../../tableWithGetDataFunction";
import { tabValuesColumns } from "./inputs";
import { useTranslation } from "react-i18next";

const { TabPane } = Tabs;

type TabValuesProps = {
  data_chart: { [s: string]: DataChart };
  variableName: string;
};

const TabValues: React.FC<TabValuesProps> = ({ data_chart, variableName }) => {
  const { t } = useTranslation();

  return (
    <Tabs className="tabStyle" defaultActiveKey="chart">
      {console.log("TEST", data_chart[variableName]?.data)}
      {Object.keys(data_chart[variableName]?.data).map((k, i) => {
        if (
          data_chart[variableName]?.data[k as Aggregation] &&
          (data_chart[variableName]?.data[k as Aggregation] || [])?.length > 0
        ) {
          return (
            <TabPane tab={k} key={i}>
              <TableWithGetDataFunction
                columns={tabValuesColumns(t)}
                data={data_chart[variableName]?.data[k as Aggregation] || []}
                icon={false}
              />
            </TabPane>
          );
        }
      })}
    </Tabs>
  );
};

export default TabValues;
