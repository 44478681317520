import "./AddSubscription.css";
import "antd/dist/antd.css";
import "../../../App.css";
import { Result } from "antd";
import React from "react";
import TitleWithBack from "../../../containers/TitleWithBack";
import { useTranslation } from "react-i18next";

const Success: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <TitleWithBack title={t("createSubscription")} />
      <Result
        status="success"
        title={t("createSubscriptionSuccess")}
        subTitle=""
      />
    </>
  );
};

export default Success;
