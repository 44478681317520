import { tableColumn } from "./tableColumns";
import "./SubscriptionsListTable.css";
import "../../../App.css";
import { getAllSubscriptions } from "../../../api/services/subscriptionService";
import React from "react";
import CustomTitle from "../../CustomTitle";
import { useHistory } from "react-router-dom";
import TableWithGetDataFunction from "../../tableWithGetDataFunction";
import { useTranslation } from "react-i18next";

type SubscriptionTableProps = {
  link: (p: string) => string;
};

const SubscriptionsListTable: React.FC<SubscriptionTableProps> = ({ link }) => {
  const { t } = useTranslation();

  const history = useHistory();
  const linkFunction: (value: string) => void = (value: string) =>
    history.push(link(value));

  return (
    <>
      <CustomTitle title={t("subscriptionsList")} />
      <TableWithGetDataFunction
        columns={tableColumn(linkFunction, t)}
        getPageAndSortedData={getAllSubscriptions}
        defaultSort="expiry_date"
        responseDataName="subscriptions"
      />
    </>
  );
};

export default SubscriptionsListTable;
