import {
  TableWithGetDataFunctionCell,
  ResponsiveTableColumnType,
} from "../../tableWithGetDataFunction";
import "../../../App.css";
import { dateTimeType, stringType } from "../../../utilities/utilities";

export const alarmColumns: (t: any) => ResponsiveTableColumnType[] = (
  t: any
) => [
  {
    label: t("start"),
    name: "start",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value) => (
        <TableWithGetDataFunctionCell type={dateTimeType} value={value} />
      ),
    },
  },
  {
    label: t("end"),
    name: "finish",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value) => (
        <TableWithGetDataFunctionCell
          type={value !== "0001-01-01T00:00:00Z" ? dateTimeType : stringType}
          value={value !== "0001-01-01T00:00:00Z" ? value : ""}
        />
      ),
    },
  },
  {
    label: t("alarm"),
    name: "tag",
    options: {
      sort: false,
      filter: true,
      customBodyRender: (value) => (
        <TableWithGetDataFunctionCell type={"string"} value={value} />
      ),
    },
  },
  {
    label: t("level"),
    name: "level",
    options: {
      sort: false,
      filter: true,
      customBodyRender: (value) => (
        <TableWithGetDataFunctionCell
          type={"tag"}
          value={value}
          color={
            value === "error" ? "red" : value === "warning" ? "orange" : "green"
          }
        />
      ),
    },
  },
  {
    label: t("startData"),
    name: "payload",
    options: {
      sort: false,
      filter: true,
      customBodyRender: (value) => (
        <TableWithGetDataFunctionCell
          type={"string"}
          value={JSON.stringify(value)}
        />
      ),
    },
  },
  {
    label: t("endData"),
    name: "payloadf",
    options: {
      sort: false,
      filter: true,
      customBodyRender: (value) => (
        <TableWithGetDataFunctionCell
          type={"string"}
          value={value !== null ? JSON.stringify(value) : ""}
        />
      ),
    },
  },
  {
    label: t("installation"),
    name: "device_name",
    options: {
      sort: false,
      filter: true,
      customBodyRender: (value) => (
        <TableWithGetDataFunctionCell type={"string"} value={value} />
      ),
    },
  },
];
