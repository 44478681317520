import {createMuiTheme} from "@material-ui/core";

export const lightTheme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#2e79ff',
    },
    secondary: {
      main: '#717171',
    },
    error: {
      main: '#b71c1c',
    },
  },
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: "#092e57",
        color: "#FFFFFF",
        boxShadow: "none"
      }
    },
    MuiChip: {
      root: {
        color: "white"
      }
    },
    MuiDrawer: {
      paper: {
        background: '#092e57',
        '& *': { color: '#FFFFFF' },
      },
    },
    MuiButton: {
      root: {
        textTransform: "none"
      }
    },
    MuiListItem: {
      root: {
        "&$selected": {
          backgroundColor: "#285f9c",
          color: "white",
          "& .MuiListItemIcon-root": {
            color: "white"
          }
        }
      }
    },
  },
  props: {
    MuiAppBar: {
      color: 'default',
    }
  },
  typography: {
    fontFamily: '"Montserrat"',
    button: {
      textTransform: "none"
    }
  },
} as any)