import "./charts/lineChart/LineChart.css";
import "../../../App.css";
import { DataChart } from "../../../api/requests/timeSeries";
import React, { useCallback, useEffect, useState } from "react";
import { Form, Select } from "antd";
import { Aggregation } from "../../../actions/lineChart";
import { avg, min, max } from "../../../actions/constants";
import LineChart from "../../../containers/pages/installationPage/charts/lineChart/LineChart";
import { LineChartOutlined } from "@ant-design/icons";
import { Installation } from "../../../api/requests/installationsService";
import { getInstallation } from "../../../api/services/installationsService";
import Chart from "./Chart";
import {
  helios4,
  heliosCorrosionModel,
  heliosMagma,
  heliosMagmaModel,
} from "./costant";
import { useTranslation } from "react-i18next";
import Title from "antd/es/typography/Title";

const { Option } = Select;

const shortPeriod = [
  { value: "300000-5m", label: "5 minuti" },
  { value: "600000-10m", label: "10 minuti" },
  { value: "1800000-30m", label: "30 minuti" },
  { value: "3600000-1h", label: "1 ora" },
];
const longPeriod = [
  { value: "3600000-1h", label: "1 ora" },
  { value: "10800000-5h", label: "5 ore" },
  { value: "36000000-10h", label: "10 ore" },
];
const date = [
  { value: 2, label: "Ultime 2 ore", period: shortPeriod },
  { value: 4, label: "Ultime 4 ore", period: shortPeriod },
  { value: 24, label: "Ultimo giorno", period: shortPeriod },
  { value: 48, label: "Ultimi 2 giorni", period: shortPeriod },
  { value: 168, label: "Ultima settimana", period: longPeriod },
  { value: 720, label: "Ultimo mese", period: longPeriod },
  { value: 1440, label: "Ultimi 2 mesi", period: longPeriod },
  { value: 2160, label: "Ultimi 3 mesi", period: longPeriod },
];

type ChartSessionProps = {
  setDataStartDate: (
    dev: string,
    a: Aggregation[],
    startDate: Date,
    period: string,
    interval: number,
    variable: any
  ) => void;
  setPeriod: (
    dev: string,
    a: Aggregation[],
    startDate: Date,
    period: string,
    interval: number,
    variable: any
  ) => void;
  startUpdateChartData: (
    dev: string,
    a: Aggregation[],
    period: string,
    interval: number,
    variable: any
  ) => void;
  clearDataInterval: () => void;
  data_chart: { [s: string]: DataChart };
  installation_id: string;
};

const dateChange = "date";
const periodChange = "period";

const ChartSession: React.FC<ChartSessionProps> = ({
  setDataStartDate,
  setPeriod,
  startUpdateChartData,
  clearDataInterval,
  data_chart,
  installation_id,
}) => {
  const { t } = useTranslation();

  const [selectedInstallation, setSelectedInstallation] =
    useState<Installation | null>(null);
  const [form] = Form.useForm();
  const [currentDate, setCurrentDate] = useState<any>(date[2]);

  const getVariablesFromModel = useCallback(
    (installation: Installation | null) => {
      if (installation !== null) {
        switch (installation.model) {
          case heliosCorrosionModel:
            return helios4;
          case heliosMagmaModel:
            return heliosMagma;
          default:
            return [];
        }
      } else {
        return [];
      }
    },
    [selectedInstallation]
  );

  const model = getVariablesFromModel(selectedInstallation);

  const [width, setWidth] = useState<number>(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    startUpdateChartData(
      installation_id || "",
      [avg, min, max],
      "10m",
      600000,
      heliosMagma
    );
    getInstallation({ id: installation_id }).then((res) => {
      if (res && res.installation) {
        setSelectedInstallation(res.installation);
      }
    });
    return clearDataInterval;
  }, []);

  const handleSelectOnChange = useCallback(
    (type: string) => {
      form.validateFields().then((values) => {
        let startDate = new Date();
        startDate.setHours(startDate.getHours() - values.date);
        let period: string[] = values.period.split("-");

        if (type === periodChange) {
          clearDataInterval();
          setPeriod(
            installation_id || "",
            [avg, min, max],
            startDate,
            period[1],
            Number(period[0]),
            model
          );
        }
        if (type === dateChange) {
          clearDataInterval();
          const filter_date: any[] = date.filter(
            (item) => String(item.value) === String(values.date)
          );
          setCurrentDate(filter_date[0]);
          if (filter_date[0].period !== period) {
            period = filter_date[0].period[0].value.split("-");
          }
          form.setFieldsValue({ period: filter_date[0].period[0].value });
          setDataStartDate(
            installation_id || "",
            [avg, min, max],
            startDate,
            period[1],
            Number(period[0]),
            model
          );
        }
      });
    },
    [selectedInstallation]
  );

  const initial_zoom = {
    data: {},
    left: "",
    right: "",
    refAreaLeft: "",
    refAreaRight: "",
    top: "dataMax+1",
    bottom: "dataMin-1",
    animation: true,
    isZoomed: false,
  };
  if (selectedInstallation === null) {
    return null;
  }
  return (
    <>
      <Form
        layout={width > 575 ? "vertical" : "vertical"}
        className={width > 575 ? "my-select-container" : undefined}
        key={1}
        form={form}
        name="installation_panel"
      >
        <Form.Item
          className={width > 575 ? "my-select" : undefined}
          label={t("data")}
          name={"date"}
          initialValue={24}
        >
          <Select
            size="large"
            placeholder="..."
            onChange={() => {
              handleSelectOnChange(dateChange);
            }}
          >
            {date.map((el, index) => {
              return (
                <Option value={el.value} key={index}>
                  {el.label}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          className={width > 575 ? "my-select" : undefined}
          label={t("aggregationInterval")}
          name={"period"}
          initialValue={currentDate.period[1].value}
        >
          <Select
            size="large"
            placeholder="..."
            onChange={() => {
              handleSelectOnChange(periodChange);
            }}
          >
            {currentDate.period.map((el: any, index: number) => {
              return (
                <Option value={el.value} key={index}>
                  {el.label}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      </Form>
      {model.map((el, index) => {
        return (
          <Chart
            key={index}
            width={width}
            title={el.label}
            icon={<LineChartOutlined />}
            chart={
              <LineChart
                data_chart={data_chart[el.key]?.data || []}
                zoom={data_chart[el.key]?.zoom || initial_zoom}
                aggregations_view={data_chart[el.key]?.aggregations || []}
                width={width}
                variableName={el.key}
              />
            }
            datachart={data_chart}
            value={el.key}
          />
        );
      })}
      <div className="dataDiv">
        <Title level={5} className="tableTitle">
          {t("sensorSummary")}
        </Title>
        <LineChart
          data_chart={{
            s2: data_chart?.s2?.data?.avg || [],
            s3: data_chart?.s3?.data?.avg || [],
            s4: data_chart?.s4?.data?.avg || [],
            s5: data_chart?.s5?.data?.avg || [],
            s6: data_chart?.s6?.data?.avg || [],
            s7: data_chart?.s7?.data?.avg || [],
            s8: data_chart?.s8?.data?.avg || [],
          }}
          zoom={data_chart?.summary?.zoom || initial_zoom}
          aggregations_view={
            data_chart?.summary?.aggregations || [
              "s2",
              "s3",
              "s4",
              "s5",
              "s6",
              "s7",
              "s8",
            ]
          }
          width={width}
          variableName="summary"
        />
      </div>
    </>
  );
};
export default ChartSession;
