import React, { useEffect, useState } from "react";
import { Descriptions, Tag } from "antd";
import { GetUserProfile } from "../../../api/services/userService";
import { UserType } from "../../types/profile/types";
import { useHistory } from "react-router-dom";
import moment from "moment";
import "./Profile.css";
import { useTranslation } from "react-i18next";

const Profile: React.FC = () => {
  const { t } = useTranslation();

  const [profileItem, setProfileItem] = useState<UserType | null>(null);

  const history = useHistory();
  useEffect(() => {
    GetUserProfile().then((res) => {
      if (res && res.user) {
        setProfileItem({
          ...profileItem,
          ...res.user,
        });
      } else {
        history.push("/");
      }
    });
  }, []);

  const dateFormatter = (date: string) =>
    moment(date).format("DD/MM/YYYY HH:mm");

  if (profileItem) {
    return (
      <div className="profileDescriptionContainer">
        <Descriptions
          className="profileDescription profileDescriptionResponsive"
          title={t("userData")}
          column={1}
          bordered
        >
          <Descriptions.Item label={t("name")}>
            {profileItem.name}
          </Descriptions.Item>
          <Descriptions.Item label={t("email")}>
            {profileItem.email}
          </Descriptions.Item>
          <Descriptions.Item label={t("role")}>
            {profileItem.role && profileItem.role.length === 1 ? (
              <Tag color="blue">{profileItem.role[0]}</Tag>
            ) : (
              ""
            )}
          </Descriptions.Item>
          <Descriptions.Item label={t("phone")}>
            {profileItem.user_info.phone}
          </Descriptions.Item>
          <Descriptions.Item label={t("address")}>
            {profileItem.user_info.address}
          </Descriptions.Item>
          <Descriptions.Item label={t("city")}>
            {profileItem.user_info.city}
          </Descriptions.Item>
          <Descriptions.Item label={t("province")}>
            {profileItem.user_info.province}
          </Descriptions.Item>
          <Descriptions.Item label={t("country")}>
            {profileItem.user_info.country}
          </Descriptions.Item>
          <Descriptions.Item label={t("zipCode")}>
            {profileItem.user_info.zip_code}
          </Descriptions.Item>
          <Descriptions.Item label={t("creationDate")}>
            {dateFormatter(profileItem.created_at)}
          </Descriptions.Item>
        </Descriptions>
      </div>
    );
  } else return null;
};

export default Profile;
