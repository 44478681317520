export const emailRules = (label: string, t: any) => [
  {
    required: true,
    message: t("requiredField"),
  },
  ({ getFieldValue }: any) => ({
    validator(value: any) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!value || re.test(getFieldValue(label))) {
        return Promise.resolve();
      }
      return Promise.reject(t("emailNotValid"));
    },
  }),
];

export const passwordRules = (t: any) => [
  ({ getFieldValue }: any) => ({
    validator(value: any) {
      if (
        !value ||
        (/.*\d.*/.test(getFieldValue("password")) &&
          /.*[A-Z].*/.test(getFieldValue("password")) &&
          /.*[a-z].*/.test(getFieldValue("password")) &&
          /.*[~!].*/.test(getFieldValue("password")))
      ) {
        return Promise.resolve();
      }
      return Promise.reject(t("passwordRule"));
    },
  }),
];

export const confirmPasswordRules = (t: any) => [
  ({ getFieldValue }: any) => ({
    validator(value: any) {
      if (
        !value ||
        getFieldValue("password") === getFieldValue("confirm_password")
      ) {
        return Promise.resolve();
      }
      return Promise.reject(t("passwordMatching"));
    },
  }),
];
