import { emailRules } from "../../types/form/rules";
import { InputType } from "../../types/form/types";

export const companyInfo: (t: any) => InputType[] = (t: any) => [
  {
    key: "company_name",
    name: "company_name",
    path: "name",
    label: t("name"),
    rules: [{ required: true, message: t("requiredField") }],
  },
  {
    key: "company_sdi",
    name: "company_sdi",
    path: "sdi",
    label: t("sdi"),
    rules: [{ required: true, message: t("requiredField") }],
  },
  {
    key: "company_pec",
    name: "company_pec",
    label: t("pec"),
    path: "pec",
    rules: emailRules("company_pec", t),
  },
  {
    key: "company_vat_number",
    name: "company_vat_number",
    label: t("vat"),
    path: "vat_number",
    type: "number",
    rules: [{ required: true, message: t("requiredField") }],
  },
  {
    key: "company_phone",
    name: "company_phone",
    label: t("phone"),
    path: "phone",
    type: "number",
    rules: [{ required: true, message: t("requiredField") }],
  },
  {
    key: "company_address",
    name: "company_address",
    label: t("address"),
    path: "address",
    rules: [{ required: true, message: t("requiredField") }],
  },
  {
    key: "company_city",
    name: "company_city",
    label: t("city"),
    path: "city",
    rules: [{ required: true, message: t("requiredField") }],
  },
  {
    key: "company_province",
    name: "company_province",
    label: t("province"),
    path: "province",
    rules: [{ required: true, message: t("requiredField") }],
  },
  {
    key: "company_country",
    name: "company_country",
    label: t("country"),
    path: "country",
    type: "select",
    rules: [{ required: true, message: t("requiredField") }],
  },
  {
    key: "company_zip_code",
    name: "company_zip_code",
    label: t("zipCode"),
    path: "zip_code",
    rules: [{ required: true, message: t("requiredField") }],
  },
];
